import { createModule } from '@conversa/sink';
import { PatientsDetailState } from '../models';
import * as projections from './projections';
import * as sinks from './sinks';
import { patientsDetailStore as store } from './store';

export const patientsDetailModule = createModule<PatientsDetailState>({
  projections,
  sinks,
  store,
});
