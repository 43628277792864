























































































































import { defineComponent, PropType } from '@vue/composition-api';
import { ActivityStatus } from '@/shared';
import MultiSelect from './MultiSelect.vue';
export default defineComponent({
  props: {
    activity: {
      type: Array as PropType<ActivityStatus[]>,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
  },
  components: {
    MultiSelect,
  },
});
