import { createProjector } from '@conversa/sink';
import { Demographics } from '@conversa/bedazzled/src/models/demographics.interface';
import { CapabilitiesState } from '@conversa/bedazzled/src/models/capabilities-state.interface';
import { LanguagesCapabilities } from '@conversa/bedazzled/src/models/languages-capabilities.interface';
import { VuetifySelectOption } from '@conversa/bedazzled/src/models/select-options.interface';

export interface CapabilitiesProjectionsMap {
  'capabilities.loading': boolean;
  'capabilities.features.caregiver': boolean;
  'capabilities.features.charting': boolean;
  'capabilities.features.feedback': boolean;
  'capabilities.features.patientEdit': boolean;
  'capabilities.features.reports': boolean;
  'capabilities.features.restrictEnrollment': boolean;
  'capabilities.features.sms': boolean;
  'capabilities.demographics': Demographics;
  'capabilities.languages': LanguagesCapabilities;
  'capabilities.enabledLanguages.selectOptions': VuetifySelectOption[];
  'capabilities.enabledLanguages.hasMultiple': boolean;
  'capabilities.international.datetimeFormat': string;
}

export const capabilitiesProjector = createProjector<
  CapabilitiesProjectionsMap,
  CapabilitiesState
>();
