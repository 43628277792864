import { tableMessages } from './../../shared/empty-table-messages';
import { createStoreV2 } from '@conversa/sink';
import { PatientsState } from '../models';

export const patientsStateKey = 'patients-state';

export const patientsInitialState: PatientsState = {
  data: [],
  count: null,
  error: null,
  loading: false,
  page: 1,
  sortCol: 'createdAt',
  sortDir: 'desc',
  filtersDirty: false,
  tableMessage: tableMessages.preSearch,
  filters: {
    activeCaregiver: null,
    clinicPatientCode: null,
    createdAtFrom: '',
    createdAtTo: '',
    email: null,
    firstName: null,
    language: [], // TODO: rename to languages
    lastName: null,
    patientStatus: null,
    phone: null,
    searchSubOrgs: null,
    providerIds: [],
    checkPhoneBlocklisted: null,
  },
  newlyAddedPatient: null,
};

export const patientsStore = createStoreV2(
  patientsStateKey,
  patientsInitialState,
);
