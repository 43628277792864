import Vue from 'vue';
import { createSink } from '@conversa/sink';
import { ActivitiesExportFailed } from '@/activity';
import { CapabilitiesLoaded } from '@/capabilities';
import { FeedbackExportFailed } from '@/feedback';
import { MiscState } from '@/models';
import { ResponsesExportFailed } from '@/responses';
import { Deeplink } from '@/shared/models/deeplink.interface';
import {
  DeeplinkFetchBegan,
  DeeplinkFetched,
  DeeplinkFetchFailed,
  DeeplinkFetchFailedDismissDialog,
  DeeplinkValidationFailed,
  ExportFailed,
  ExportFailedDismissDialog,
} from './events';
import {
  UserAccessTokenInvalid,
  UserAccessTokenInvalidCancelled,
  UserAccessTokenMissing,
} from '@/user';
import { axiosWithAuth } from '@/shared/axios-with-auth';
import { isAxiosError } from '@/shared';
import { handleApiError } from '@/shared/api/set-store-error';
import {
  deeplinkIdCookieName,
  isDeeplinkValid,
  partnerAuthCookieName,
} from '@/shared/deeplink';

export const miscExportFailed = createSink<
  MiscState,
  ReturnType<typeof ExportFailed>
>({
  sources: [
    FeedbackExportFailed,
    ActivitiesExportFailed,
    ResponsesExportFailed,
  ],
  async sink({ event, broadcast, store }) {
    // This could be updated with the actual error passed in from the event instead of a generic message
    event.payload.message =
      'Please refresh the page to try again. If this persists, please contact support.';
    store.error = event.payload;
    broadcast(ExportFailed(event.payload));
  },
});

export const clearMiscError = createSink<
  MiscState,
  ReturnType<
    typeof ExportFailedDismissDialog | typeof DeeplinkFetchFailedDismissDialog
  >
>({
  sources: [ExportFailedDismissDialog, DeeplinkFetchFailedDismissDialog],
  async sink({ store }) {
    store.error = null;
  },
});

// -------- Deeplink handlers
export const redirectToDeeplinkFetch = createSink<
  MiscState,
  ReturnType<typeof CapabilitiesLoaded>
>({
  sources: [CapabilitiesLoaded],
  // Only attempt to fetch the deeplink data if we have the cookie AND we haven't fetched it before
  // The intent is that store.deeplink gets cleared when reloading or clicking through the deeplink again
  gate: ({ store }) =>
    !!Vue.$cookies.get(deeplinkIdCookieName) && !store.deeplink,
  async sink({ store, broadcast, getStore }) {
    const token = getStore('user').accessToken;

    Vue.$cookies.remove(partnerAuthCookieName);
    const deeplinkSlug = Vue.$cookies.get(deeplinkIdCookieName);
    broadcast(DeeplinkFetchBegan()); // this might trigger a loader message

    try {
      const { data } = await axiosWithAuth(token, broadcast).get<Deeplink>(
        `/api/vista/deeplink/detail/${deeplinkSlug}`,
      );

      Vue.$cookies.remove(deeplinkIdCookieName);

      if (isDeeplinkValid(data)) {
        store.deeplink = data;
        broadcast(DeeplinkFetched(data));
      } else {
        broadcast(DeeplinkValidationFailed());
      }
    } catch (error) {
      console.warn(error);
      broadcast(DeeplinkFetchFailed());

      if (!isAxiosError(error)) {
        return;
      }

      handleApiError(store, error);
    }
  },
});

export const redirectToDeeplinkAuth = createSink<
  MiscState,
  ReturnType<typeof UserAccessTokenMissing>
>({
  sources: [UserAccessTokenMissing],
  async sink({ broadcast }) {
    broadcast(UserAccessTokenInvalidCancelled());

    const partnerAuthUrl = Vue.$cookies.get(partnerAuthCookieName);

    if (partnerAuthUrl) {
      try {
        const url = new URL(partnerAuthUrl); // validate the URL is actually a URL
        window.location.href = url.toString();
        return;
      } catch (e) {
        console.error('partnerAuthUrl set but not a valid URL', e.message);
      }
    }

    broadcast(UserAccessTokenInvalid());
  },
});
