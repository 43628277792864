



















import DialogTitle from '@/dialog/DialogTitle.vue';
import { DialogInstance } from '@conversa/bedazzled/src/dialog';
import { InactivityTimerCancelled } from '@/user/+state';
import { defineComponent } from '@vue/composition-api';
import sink from '@/sink';

export default defineComponent({
  components: {
    DialogTitle,
  },
  setup() {
    return {
      close: () => {
        sink.broadcast(InactivityTimerCancelled());
        DialogInstance.close();
      },
    };
  },
});
