var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-select',{attrs:{"items":[
    {
      value: 'started',
      text: 'Started',
    },
    {
      value: 'qualified',
      text: 'Qualified',
    },
    {
      value: 'patient_completed',
      text: 'Patient Completed',
    },
    {
      value: 'system_completed',
      text: 'System Completed',
    },
    {
      value: 'ignored',
      text: 'Ignored',
    },
    {
      value: 'abandoned',
      text: 'Abandoned',
    } ],"value":_vm.status,"id":_vm.id,"name":"last-chat-status","label":"Last Chat Status","placeholder":"--","clearable":""},on:{"change":function($event){return _vm.$emit('change', $event)}}})}
var staticRenderFns = []

export { render, staticRenderFns }