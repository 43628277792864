







import { defineComponent } from '@vue/composition-api';
import { ErrorDialog } from '@/shared/components';
import { DialogInstance } from '@conversa/bedazzled/src/dialog';
import sink from '@/sink';
import { DeeplinkFetchFailedDismissDialog } from '@/+state';

export default defineComponent({
  components: { ErrorDialog },
  setup() {
    return {
      dismissed: () => {
        sink.broadcast(DeeplinkFetchFailedDismissDialog());
        DialogInstance.close();
      },
    };
  },
});
