





















import sink from '@/sink';
import { defineComponent } from '@vue/composition-api';
import { DialogInstance } from '@conversa/bedazzled/src/dialog';
import { LogoutButtonClicked } from '../../+state/events';
import DialogTitle from '@/dialog/DialogTitle.vue';

export default defineComponent({
  components: {
    DialogTitle,
  },
  setup() {
    return {
      acknowledge: () => DialogInstance.close(),
      relogin: sink.lazyBroadcast(
        LogoutButtonClicked({ origin: 'session-expiring' }),
      ),
    };
  },
});
