import { tableMessages } from '@/shared';
import { createStoreV2 } from '@conversa/sink';
import { PatientDetailsActivitiesState } from '../../models/patient-details-activities-state.interface';

export const patientsDetailStateKey = 'patients-detail-activities';

export const patientsDetailActivitiesInitialState: PatientDetailsActivitiesState = {
  loading: false,
  data: [],
  count: 0,
  page: 1,
  sortCol: 'activityDate',
  sortDir: 'desc',
  tableMessage: tableMessages.preSearch,
};

export const patientsDetailActivitiesStore = createStoreV2(
  patientsDetailStateKey,
  patientsDetailActivitiesInitialState,
);
