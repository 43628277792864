import { createProjector } from '@conversa/sink';
import { handleErrorMessage } from '@/shared';
import * as models from '@/models';

export interface MiscProjectionsMap {
  'exports.error-message': string;
}

const projector = createProjector<MiscProjectionsMap, models.MiscState>();

export const miscStoreErrorMessage = projector<string>(
  'exports.error-message',
  handleErrorMessage,
);
