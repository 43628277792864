import { tableMessages } from '@/shared';
import { createStoreV2 } from '@conversa/sink';
import { PatientDetailsCompletedChatsState } from '../models/store.interface';

export const patientDetailsCompletedChatsStateKey =
  'patient-details-completed-chats';

export const patientDetailsCompletedChatsInitialState: PatientDetailsCompletedChatsState = {
  loading: false,
  error: null,
  data: [],
  count: 0,
  exRows: [],
  page: 1,
  sortedCol: 'chatDate',
  sortDir: 'desc',
  tableMessage: tableMessages.preSearch,
};

export const patientDetailsCompletedChatsStore = createStoreV2(
  patientDetailsCompletedChatsStateKey,
  patientDetailsCompletedChatsInitialState,
);
