import { action } from 'ts-action';

export const PatientDetailMounted = action(
  '[ component | PatientDetailShell ] Patient Detail Mounted',
);

export const PatientDetailUnmounted = action(
  '[ component | PatientsDetailShell ] Patient Detail Unmounted',
);

export const PatientDetailFetched = action(
  '[ api | /api/vista/patients/:patientId ] Patient Detail Fetched',
);
