import { ErrorState } from '@conversa/bedazzled/src/models/error-state.interface';
import { action, payload } from 'ts-action';
import { Deeplink } from '@/shared/models/deeplink.interface';

export const AppMounted = action('[ component | App ] App Mounted');

export const TokenSet = action('[ component | App ] Token Set');

export const ExportFailed = action(
  '[ api | App ] Export Failed',
  payload<ErrorState>(),
);

export const ExportFailedDismissDialog = action(
  '[ api | App ] Dismiss Export Failed Dialog',
);

// --- Deeplink
export const DeeplinkFetched = action(
  '[ api | /deeplink/detail ] Deeplink Fetched',
  payload<Deeplink>(),
);

export const DeeplinkFetchFailed = action(
  '[ api | /deeplink/detail ] Deeplink Fetch Failed',
);

export const DeeplinkFetchFailedDismissDialog = action(
  '[ api | /deeplink/detail ] Dismiss Deeplink Fetch Failed Dialog',
);

export const DeeplinkFetchBegan = action(
  '[ api | /deeplink/detail ] Deeplink Fetch Began',
);

export const DeeplinkValidationFailed = action(
  '[ api | /deeplink/detail ] Deeplink Validation Failed',
);
