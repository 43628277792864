import { createProjector } from '@conversa/sink';
import { AccessTokenDecoded, IdTokenDecoded, UserState } from '../models';
import { AccountInfo } from '../models/account-info';

export interface UserProjectionsMap {
  'user.account-info': AccountInfo;
  'user.error': string;
  'user.tokens.access.decoded': AccessTokenDecoded;
  'user.tokens.id.decoded': IdTokenDecoded;
  'user.error-message': string;
}

export const userProjector = createProjector<UserProjectionsMap, UserState>();
