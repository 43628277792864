import { ReportState } from './../models/report-state';
import {
  ReportRunRequested,
  ReportSelected,
  ReportLoadFailed,
  ReportsAvailableRequested,
  ReportsAvailableLoadFailed,
  ReportFiltersChanged,
  ReportFiltersCleared,
  ReportActionsMounted,
} from './events';
import { axiosWithAuth } from '@/shared/axios-with-auth';
import { createSink } from '@conversa/sink';
import { isAxiosError, filterInputUpdate } from '@/shared';
import { ReportRequestPayload } from '@/reporting/models';
import { routerStateKey } from '@/router/+state/store';
import { organizationsStateKey } from '@/organizations/+state/store';
import { OrganizationChanged, OrganizationsLoaded } from '@/organizations';

/**
 * For use in a reducer on the filters object, returns a values object with the filter defaults.
 */
function reduceFiltersForDefaults(newFilters, filter): Record<string, string> {
  newFilters[filter.name] = filter.default;

  return newFilters;
}

export const selectedReportChanged = createSink<
  ReportState,
  ReturnType<typeof ReportSelected>
>({
  sources: [ReportSelected],
  sink({ store, event, broadcast }) {
    store.selectedReport = event.payload.reportId;
    store.filters = store.filtersRaw
      ? store.filtersRaw.reduce(reduceFiltersForDefaults, {})
      : {};
    broadcast(ReportRunRequested({ runType: 'reportChange' }));
  },
});

export const reportsFilterChanged = createSink<
  ReportState,
  ReturnType<typeof ReportFiltersChanged>
>({
  sources: [ReportFiltersChanged],
  sink: filterInputUpdate,
});

export const reportsFilterCleared = createSink<
  ReportState,
  ReturnType<typeof ReportFiltersCleared>
>({
  sources: [ReportFiltersCleared],
  sink({ store }) {
    if (store.filtersRaw) {
      store.filters = store.filtersRaw.reduce(reduceFiltersForDefaults, {});
    } else {
      store.filters = {};
    }
  },
});

export const reportsAvailable = createSink<
  ReportState,
  ReturnType<
    | typeof ReportActionsMounted
    | typeof ReportsAvailableRequested
    | typeof OrganizationsLoaded
    | typeof OrganizationChanged
  >
>({
  sources: [
    ReportActionsMounted,
    ReportsAvailableRequested,
    OrganizationsLoaded,
    OrganizationChanged,
  ],
  gate: ({ getStore }) => {
    const isReportingRoute = getStore(routerStateKey).route?.path.startsWith(
      '/reporting',
    );
    const hasSelectedOrgId = !!getStore(organizationsStateKey).selectedOrgId;

    return isReportingRoute && hasSelectedOrgId;
  },
  async sink({ store, broadcast, getStore, select }) {
    const token = getStore('user').accessToken;
    const selectedOrgId = getStore('organizations-state').selectedOrgId;
    store.filtersDirty = false;

    store.selectedReport = null;
    store.filtersRaw = null;
    store.filters = {};

    try {
      store.loadingAvailable = true;
      const { data } = await axiosWithAuth(token, broadcast).get(
        `/api/vista/reports/${selectedOrgId}`,
      );

      store.reportsAvailable = data;

      const reportId = select('router.current-report-id').value;
      if (!store.selectedReport?.length && reportId) {
        store.selectedReport = reportId;
      } else {
        store.selectedReport = data[0].id;
      }

      broadcast(ReportRunRequested({ runType: 'reportChange' }));
    } catch (error) {
      if (!isAxiosError(error)) {
        console.log(error);
        return;
      }

      store.error = error.response.data;

      broadcast(
        ReportsAvailableLoadFailed({
          status: error.response.status,
          message: error.response.data,
        }),
      );
    } finally {
      store.loadingAvailable = false;
    }
  },
});

export const reportsRun = createSink<
  ReportState,
  ReturnType<typeof ReportRunRequested>
>({
  sources: [ReportRunRequested],
  async sink({ store, event, broadcast, getStore }) {
    const token = getStore('user').accessToken;
    const selectedOrgId = getStore('organizations-state').selectedOrgId;
    store.filtersDirty = false;
    /* eslint-disable @typescript-eslint/camelcase */

    const cleanedFilters = {};

    for (const filter in store.filters) {
      let val = store.filters[filter];
      if (!val) continue;
      // NOTE: while fixing a bug we discovered some type issues with store. hence the type casting.
      // see ticket https://conversahealth.atlassian.net/browse/PDI-931
      // This transformation is to help enable a sql injection sanitization setup in the vista api
      const isArr = Array.isArray(val);
      const isNum = Number.isInteger(val);

      if (isArr) val = ((val as unknown) as string[]).join(',');
      if (isNum) val = ((val as unknown) as number).toString();

      cleanedFilters[filter] = val;
    }

    const payload: ReportRequestPayload = {
      report_id: store.selectedReport,
      filters: cleanedFilters,
    };

    try {
      store.loading = true;
      const { data } = await axiosWithAuth(token, broadcast).post(
        `/api/vista/reports/${selectedOrgId}`,
        payload,
      );

      if (event.payload.runType === 'reportChange') {
        store.filtersRaw = data.filters?.map(filter => {
          filter.options = filter.params.map(param => ({
            text: param.label,
            value: param.value,
          }));

          return filter;
        });
        store.filters = data.filters?.reduce(reduceFiltersForDefaults, {});
      }
      store.url = data.url;

      store.loading = false;
    } catch (error) {
      store.loading = false;
      if (!isAxiosError(error)) {
        console.log(error);
        return;
      }

      store.error = error.response.data;

      broadcast(
        ReportLoadFailed({
          status: error.response.status,
          message: error.response.data,
        }),
      );
    }
    /* eslint-enable @typescript-eslint/camelcase */
  },
});
