














import { defineComponent } from '@vue/composition-api';
import { formRules } from '../form-rules';

export default defineComponent<{
  required?: boolean;
  id: string;
  inputValue: (string | number)[] | string;
  label: string;
  name: string;
}>({
  props: ['required', 'id', 'inputValue', 'label', 'name'],
  setup(props) {
    return {
      rules: props.required ? [formRules.required] : [],
    };
  },
});
