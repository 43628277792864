const updateFilters = ({ store, event, filter, value }) => {
  if (Object.keys(store['filters']).includes(filter)) {
    store['filters'][filter] = value;
    store.filtersDirty = true;
  } else {
    console.warn(
      'Event attempted to update a property on a store that does not exist',
      { event, store },
    );
  }
};

export const filterInputUpdate = ({ event, store }) => {
  updateFilters({
    store,
    event,
    filter: event.payload.input,
    value: event.payload.value,
  });
};

export const restoreFilters = ({ store, filters, event }) => {
  Object.keys(filters).forEach(filter =>
    updateFilters({ store, event, filter, value: filters[filter] }),
  );
};
