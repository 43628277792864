import { UserAccessTokenInvalid, UserNoValidOrgs } from '@/user/+state/events';
import axios, { AxiosInstance } from 'axios';

export class InvalidTokenError extends Error {
  constructor(message) {
    super(message);
    this.name = 'InvalidTokenError';
  }
}

export function isAuthError(
  error: InvalidTokenError | unknown,
): error is InvalidTokenError {
  return error instanceof InvalidTokenError;
}

export function axiosWithAuth(
  token: string,
  // eslint-disable-next-line
  broadcast: (event: any) => void,
): AxiosInstance {
  const axiosInstance = axios.create({
    headers: {
      'X-VISTA-AUTH': `Bearer ${token}`,
    },
  });

  axiosInstance.interceptors.response.use(
    function(response) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      return response;
    },
    function(error) {
      // errors here might not have an HTTP response, such as timeouts
      const { status } = error?.response;

      if (typeof status !== 'undefined') {
        switch (status) {
          case 400:
            if (
              error.response.data?.message ===
              'token references no valid organizations'
            ) {
              broadcast(UserNoValidOrgs());
              return Promise.reject(
                new InvalidTokenError(error.response.data.message),
              );
            }
            break;
          case 401:
            broadcast(UserAccessTokenInvalid());
            return Promise.reject(new InvalidTokenError('auth token invalid'));
            break;
        }
      }

      return Promise.reject(error);
    },
  );

  return axiosInstance;
}
