import { createModule } from '@conversa/sink';
import { FeedbackState } from '../models';
import * as projections from './projections';
import * as sinks from './sinks';
import { feedbackStore as store } from './store';

export * from './projections-map';
export * from './events';
export const feedbackModule = createModule<FeedbackState>({
  projections,
  sinks,
  store,
});
