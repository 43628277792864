import { UserState } from '../models';
import { createStoreV2 } from '@conversa/sink';

export const userStateKey = 'user';

export const initialUserState: UserState = {
  accessToken: null,
  alertThreshold: null,
  email: null,
  error: null,
  id: null,
  idToken: null,
  loading: false,
  nameFirst: null,
  nameLast: null,
  alertNotificationMethod: null,
  mobilePhone: null,
  contactPhone: null,
};

export const userStore = createStoreV2(userStateKey, initialUserState);
