import { Provider } from './../models/provider.interface';
import { providersProjector } from './projections-map';
import { VuetifySelectOption } from '@/shared/models/select-options.interface';

export const loadingProviders = providersProjector<boolean>(
  'providers.loading',
  ({ store }) => store.loadingProviders,
);

export const providers = providersProjector<Provider[]>(
  'providers.providers',
  ({ store }) => store.providers,
);

export const providersForSelect = providersProjector<VuetifySelectOption[]>(
  'providers.select-options',
  ({ store }) =>
    (store.providers || []).map(provider => ({
      value: provider.id,
      text: `${provider.first_name} ${provider.last_name}`,
    })),
);
