import { createModule } from '@conversa/sink';
import * as projections from './projections';
import * as sinks from './sinks';
import { PatientsDetailSummaryEnrollmentsState } from '../models';
import { patientDetailSummaryEnrollmentsStore as store } from './store';

export const patientsDetailSummaryEnrollmentsModule = createModule<
  PatientsDetailSummaryEnrollmentsState
>({
  projections,
  sinks,
  store,
});
