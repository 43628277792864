/* eslint-disable @typescript-eslint/camelcase */
import { tableMessages } from '@/shared';
import { axiosWithAuth } from '@/shared/axios-with-auth';
import { sortColMap } from '@/shared/sort-col-map';
import { createSink } from '@conversa/sink';
import { PatientDetailFetched } from '../../+state/events';
import { PatientDetailsResponsesState } from '../../models';
import {
  PatientDetailsResponsesTablePageUpdated,
  PatientDetailsResponsesTableSorted,
  PatientDetailsResponsesFetched,
  PatientDetailsResponsesFilteredFetched,
  PatientDetailsResponsesFiltersCleared,
} from './events';
import { PatientsState } from '@/patients/models';
import { patientsStateKey } from '@/patients/+state/store';

export const patientDetailsResponsesRequested = createSink<
  PatientDetailsResponsesState,
  ReturnType<typeof PatientDetailsResponsesFetched>
>({
  sources: [PatientDetailsResponsesFetched, PatientDetailFetched],
  async sink({ store, broadcast, getStore, select }) {
    const router = getStore('router');
    const token = getStore('user').accessToken;
    const patientsStore: PatientsState = getStore(patientsStateKey);

    const patientId =
      select('router.current-patient-id').value ||
      patientsStore.newlyAddedPatient.id;

    const clinicPatientCode = getStore('patients-detail').data
      ?.clinic_patient_code;

    if (!clinicPatientCode || router.route?.name !== 'patientResponses') return;

    try {
      store.loading = true;

      const { data } = await axiosWithAuth(token, broadcast).get(
        `/api/vista/patients/${patientId}/responses`,
        {
          params: {
            with_count: true,
            page: store.page,
            sort_col: sortColMap.get(store.sortCol),
            sort_dir: store.sortDir,
            question_text: store.filters.question_text || '',
          },
        },
      );

      if (!data.data?.length) {
        store.tableMessage = tableMessages.noResults;
      }

      store.data = data.data;
      store.count = data.count;
    } catch (error) {
      store.data = [];
      store.tableMessage = tableMessages.serverError;
    } finally {
      store.loading = false;
    }
  },
});

export const patientDetailsResponsesTableSorted = createSink<
  PatientDetailsResponsesState,
  ReturnType<typeof PatientDetailsResponsesTableSorted>
>({
  sources: [PatientDetailsResponsesTableSorted],
  sink({ store, event, broadcast }) {
    store.sortCol = event.payload.col;
    store.sortDir = event.payload.dir;
    store.page = 1;
    broadcast(PatientDetailsResponsesFetched());
  },
});

export const patientDetailsResponsesPageUpdated = createSink<
  PatientDetailsResponsesState,
  ReturnType<typeof PatientDetailsResponsesTablePageUpdated>
>({
  sources: [PatientDetailsResponsesTablePageUpdated],
  sink({ store, event, broadcast }) {
    store.page = event.payload.page;
    broadcast(PatientDetailsResponsesFetched());
  },
});

export const patientDetailsResponsesFilteredRequested = createSink<
  PatientDetailsResponsesState,
  ReturnType<typeof PatientDetailsResponsesFilteredFetched>
>({
  sources: [PatientDetailsResponsesFilteredFetched],
  sink({ store, event, broadcast }) {
    store.filters = event.payload.filters;
    if (event.payload.reload) broadcast(PatientDetailsResponsesFetched());
  },
});

export const patientDetailsResponsesFiltersReseted = createSink<
  PatientDetailsResponsesState,
  ReturnType<typeof PatientDetailsResponsesFiltersCleared>
>({
  sources: [PatientDetailsResponsesFiltersCleared],
  sink({ store, broadcast }) {
    store.filters = {};
    broadcast(PatientDetailsResponsesFetched());
  },
});
