import { action, payload } from 'ts-action';
import { Capabilities } from '@conversa/bedazzled/src/models/capabilities.interface';

export const CapabilitiesLoadFailed = action(
  '[ api | vista/access/capabilities ] Capabilities Load Failed',
  payload<{
    status: number;
    // eslint-disable-next-line
    message: any;
  }>(),
);

export const CapabilitiesLoaded = action(
  '[ api | capabilities ] Capabilities Loaded',
  payload<Capabilities>(),
);
