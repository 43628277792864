import { ref } from '@vue/composition-api';

export function useIsDesktopListener() {
  const mediaQueryList = window.matchMedia('(min-width: 1140px)');
  const isDesktop = ref(mediaQueryList.matches);
  mediaQueryList.addEventListener(
    'change',
    ({ matches }: MediaQueryListEvent) => (isDesktop.value = matches),
  );

  return isDesktop;
}
