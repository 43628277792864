import { action, payload } from 'ts-action';

export const TokenHashParsed = action(
  '[ effects | user ] Token Hash Parsed',
  payload<{ params: URLSearchParams }>(),
);

export const TokenLoaded = action(
  '[ effects | user ] Token Loaded',
  payload<{ key: 'access' | 'id'; token: string }>(),
);
