import { SortCol } from './models';

export const sortColMap = new Map<SortCol, SortCol>([
  ['activityDate', 'activity_created_at'],
  ['chatDate', 'chat_date'],
  ['chatsIgnored', 'no_response_count'],
  ['chatStatus', 'chat_status_numerical'],
  ['createdAt', 'created_at_date'],
  ['enrollmentDate', 'enrollment_created_at'],
  ['feedbackDate', 'created_at_date'],
  ['lastChatDate', 'last_completed_at'],
  ['lastChatStatus', 'last_chat_status'],
  ['lastScheduledChatDate', 'last_scheduled_chat_date'],
  ['nextScheduledChatDate', 'next_scheduled_chat_date'],
  ['patientName', 'patient_last_name'],
  ['program', 'chat_name'],
  ['programModule', 'survey_name'],
  ['providerName', 'provider_last_name'],
  ['question', 'question_text'],
  ['responseDate', 'response_created_at'],
  ['responseStatus', 'color'],
  ['feedbackProvider', 'provider_last_name'],
]);
