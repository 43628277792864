import {
  PatientDetailsActivitiesSortCol,
  SortDir,
  TableMessage,
} from '@/shared';
import { dateFormatter } from '../../../../../../../shared/date-formatter';
import { createProjector } from '@conversa/sink';
import { PatientDetailsActivitiesState } from '../../models';
import { PatientDetailsActivityTable } from '../../models/patient-details-activity-table.interface';
import { capabilitiesStateKey } from '@/capabilities/+state/store';
import { CapabilitiesState } from '@conversa/bedazzled/src/models/capabilities-state.interface';

export interface PatientDetailsActivitiesProjectionMap {
  'patient.details.activities.table-message': TableMessage;
  'patient.details.activities.table': PatientDetailsActivityTable;
  'patient.details.activities.count': number;
  'patient.details.activities.sort-col': PatientDetailsActivitiesSortCol;
  'patient.details.activities.sort-dir': SortDir;
  'patient.details.activities.page-number': number;
  'patient.details.activities.loading': boolean;
}

const projector = createProjector<
  PatientDetailsActivitiesProjectionMap,
  PatientDetailsActivitiesState
>();

export const patientDetailsActivitiesTable = projector<
  PatientDetailsActivityTable
>('patient.details.activities.table', ({ store, getStore }) => {
  const cStore = getStore(capabilitiesStateKey) as CapabilitiesState;
  return store.data.map(activity => ({
    id: activity.id.toString(),
    activityDate: dateFormatter(
      activity.activity_created_at,
      cStore.capabilities.international.datetimeFormat,
      'dateTime',
    ),
    activity: activity.activity,
  }));
});

export const patientDetailsActivitiesLoading = projector<boolean>(
  'patient.details.activities.loading',
  ({ store }) => store.loading,
);

export const patientDetailsActivitiesTableMessage = projector<TableMessage>(
  'patient.details.activities.table-message',
  ({ store }) => store.tableMessage,
);

export const patientDetailsActivitiesCount = projector<number>(
  'patient.details.activities.count',
  ({ store }) => store.count,
);

export const patientDetailsActivitiesSortCol = projector<
  PatientDetailsActivitiesSortCol
>('patient.details.activities.sort-col', ({ store }) => store.sortCol);

export const patientDetailsActivitiesSortDir = projector<SortDir>(
  'patient.details.activities.sort-dir',
  ({ store }) => store.sortDir,
);

export const patientDetailsActivitiesPageNumber = projector<number>(
  'patient.details.activities.page-number',
  ({ store }) => store.page,
);
