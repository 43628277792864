














import { defineComponent } from '@vue/composition-api';

export default defineComponent<{
  language: 'en' | 'es';
  id: string;
  required?: boolean;
  items: [];
}>({
  props: ['language', 'id', 'required', 'items'],
  emits: ['change'],
});
