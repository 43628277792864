import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';

Vue.use(VueRouter);

const routes: RouteConfig[] = [
  {
    path: '/',
    components: {
      default: () => import('../home/Shell.vue'),
    },
  },
  {
    path: '/patients',
    component: () => import('../patients/Shell.vue'),
  },
  {
    path: '/patients/:patientId',
    component: () => import('../patients/detail/Shell.vue'),
    children: [
      {
        path: '',
        name: 'patientsDetailSummary',
        component: () => import('../patients/detail/summary/Shell.vue'),
      },
      {
        path: 'activity',
        name: 'patientActivity',
        component: () => import('../patients/detail/activity/Shell.vue'),
      },
      {
        path: 'responses',
        name: 'patientResponses',
        component: () => import('../patients/detail/responses/Shell.vue'),
      },
      {
        path: 'completed-chats',
        name: 'patientCompletedChats',
        component: () => import('../patients/detail/completed-chats/Shell.vue'),
      },
    ],
  },
  {
    path: '/chats',
    component: () => import('../escalations/Shell.vue'),
  },
  {
    path: '/responses',
    component: () => import('../responses/Shell.vue'),
  },
  {
    path: '/patient-engagement',
    component: () => import('../patient-engagements/Shell.vue'),
  },
  {
    path: '/activity',
    component: () => import('../activity/Shell.vue'),
  },
  {
    path: '/reporting/:reportId?',
    component: () => import('../reporting/Shell.vue'),
  },
  {
    path: '/feedback',
    component: () => import('../feedback/Shell.vue'),
  },
];

const router = new VueRouter({
  routes,
  mode: 'history',
});

export default router;
