












import {
  DeeplinkFetchFailed,
  DeeplinkValidationFailed,
  ExportFailed,
} from '@/+state';
import MiscDeeplinkFetchFailedVue from '@/misc-dialogs/MiscDeeplinkFetchFailed.vue';
import MiscExportFailedDialogVue from '@/misc-dialogs/MiscExportFailedDialog.vue';
import { PatientsSearchFailed } from '@/patients/+state/events';
import { PatientDetailsFailed } from '@/patients/detail/summary/profile/+state/events';
import PatientSearchFailedDialogVue from '@/patients/dialogs/PatientSearchFailedDialog.vue';
import {
  ReportLoadFailed,
  ReportsAvailableLoadFailed,
} from '@/reporting/+state';
import ReportLoadFailedVue from '@/reporting/dialogs/ReportLoadFailed.vue';
import ReportsAvailableLoadFailedVue from '@/reporting/dialogs/ReportsAvailableLoadFailed.vue';
import UserSessionInvalidDialogVue from '@/shared/components/UserSessionInvalidDialog.vue';
import sink from '@/sink';
import {
  InactivityWarningThresholdMet,
  UserAccessTokenInvalid,
  SessionEndingSoonThresholdMet,
  ProfileFetchFailed,
} from '@/user';
import ProfileFetchFailedDialogVue from '@/user/dialogs/profile/ProfileFetchFailedDialog.vue';
import InactivityWarningDialogVue from '@/user/dialogs/timeouts/InactivityWarningDialog.vue';
import SessionEndingSoonDialog from '@/user/dialogs/timeouts/SessionEndingSoonDialog.vue';
import { DialogInstance } from '@conversa/bedazzled/src/dialog';
import { computed, defineComponent } from '@vue/composition-api';
import { ofType } from 'ts-action-operators';
import PatientDetailsFailedDialogVue from '../patients/detail/summary/profile/PatientDetailsFailedDialog.vue';
import { map } from 'rxjs/operators';

export default defineComponent({
  props: ['dialog', 'opts'],
  setup(props) {
    sink.events$
      .pipe(
        ofType(
          InactivityWarningThresholdMet,
          ReportLoadFailed,
          UserAccessTokenInvalid,
          SessionEndingSoonThresholdMet,
          ReportsAvailableLoadFailed,
          PatientsSearchFailed,
          PatientDetailsFailed,
          ProfileFetchFailed,
          ExportFailed,
          DeeplinkFetchFailed,
          DeeplinkValidationFailed,
        ),
        map(
          e =>
            ({
              [InactivityWarningThresholdMet.type]: InactivityWarningDialogVue,
              [ReportLoadFailed.type]: ReportLoadFailedVue,
              [UserAccessTokenInvalid.type]: UserSessionInvalidDialogVue,
              [SessionEndingSoonThresholdMet.type]: SessionEndingSoonDialog,
              [ReportsAvailableLoadFailed.type]: ReportsAvailableLoadFailedVue,
              [PatientsSearchFailed.type]: PatientSearchFailedDialogVue,
              [PatientDetailsFailed.type]: PatientDetailsFailedDialogVue,
              [ProfileFetchFailed.type]: ProfileFetchFailedDialogVue,
              [ExportFailed.type]: MiscExportFailedDialogVue,
              [DeeplinkFetchFailed.type]: MiscDeeplinkFetchFailedVue,
              [DeeplinkValidationFailed.type]: MiscDeeplinkFetchFailedVue,
            }[e.type]),
        ),
      )
      .subscribe(dialog => DialogInstance.open(dialog));

    return {
      open: computed(() => !!props.dialog),
      persistent: computed(() => props.opts?.persistent ?? true),
    };
  },
});
