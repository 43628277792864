






































import { defineComponent } from '@vue/composition-api';

export default defineComponent<{
  status:
    | 'started'
    | 'qualified'
    | 'patient_completed'
    | 'system_completed'
    | 'ignored'
    | 'abandoned';
  id: string;
}>({
  props: ['status', 'id'],
});
