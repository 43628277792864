import { organizationsStateKey } from '@/organizations/+state/store';
import { tableMessages } from '@/shared';
import { axiosWithAuth } from '@/shared/axios-with-auth';
import { sortColMap } from '@/shared/sort-col-map';
import { createSink } from '@conversa/sink';
import { PatientDetailFetched } from '../../+state/events';
import { PatientDetailsCompletedChatsState } from '../models/store.interface';
import {
  FetchPatientCompletedChats,
  PatientCompletedChatsPageUpdated,
  PatientCompletedChatsResponsesRequested,
  PatientCompletedChatsRowExpanded,
  PatientCompletedChatsTableSorted,
} from './events';

export const fetchPatientCompletedChats = createSink<
  PatientDetailsCompletedChatsState,
  ReturnType<typeof FetchPatientCompletedChats>
>({
  sources: [FetchPatientCompletedChats, PatientDetailFetched],
  async sink({ store, select, broadcast, getStore }) {
    const router = getStore('router');
    const token = getStore('user').accessToken;
    const orgId = getStore(organizationsStateKey).selectedOrgId;
    const patientId = select('router.current-patient-id').value;

    if (!orgId || router.route?.name !== 'patientCompletedChats') return;

    /* eslint-disable @typescript-eslint/camelcase */
    const payload = {
      pagination: {
        with_count: true,
        sort_col: sortColMap.get(store.sortedCol),
        sort_dir: store.sortDir,
        page: store.page,
      },
      filters: {
        org_id: orgId,
        patient_id: patientId,
      },
    };
    /* eslint-enable @typescript-eslint/camelcase */

    try {
      store.loading = true;
      const { data } = await axiosWithAuth(token, broadcast).post(
        '/api/vista/escalations/search',
        payload,
      );

      if (!data.data.length) {
        store.tableMessage = tableMessages.noResults;
      }

      store.data = data.data.map(d => {
        d.responses = [];
        d.responsesLoading = false;
        d.responsesTableMessage = tableMessages.preSearch;

        return d;
      });
      store.count = data.count;
      store.exRows = [];
    } catch (err) {
      store.data = [];
      store.tableMessage = tableMessages.serverError;
    } finally {
      store.loading = false;
    }
  },
});

export const patientCompletedChatsTableSorted = createSink<
  PatientDetailsCompletedChatsState,
  ReturnType<typeof PatientCompletedChatsTableSorted>
>({
  sources: [PatientCompletedChatsTableSorted],
  sink({ store, event, broadcast }) {
    store.exRows = [];
    store.sortedCol = event.payload.col;
    store.sortDir = event.payload.dir;
    store.page = 1;

    broadcast(FetchPatientCompletedChats());
  },
});

export const patientCompletedChatsPageUpdated = createSink<
  PatientDetailsCompletedChatsState,
  ReturnType<typeof PatientCompletedChatsPageUpdated>
>({
  sources: [PatientCompletedChatsPageUpdated],
  sink({ store, event, broadcast }) {
    store.exRows = [];
    store.page = event.payload.page;

    broadcast(FetchPatientCompletedChats());
  },
});

export const patientCompletedChatsRowExpanded = createSink<
  PatientDetailsCompletedChatsState,
  ReturnType<typeof PatientCompletedChatsRowExpanded>
>({
  sources: [PatientCompletedChatsRowExpanded],
  sink({ store, event }) {
    if (event.payload.isExpanded) {
      store.exRows.push(event.payload.item);
      return;
    }

    const included = store.exRows.findIndex(
      item => item.checkupId === event.payload.item.checkupId,
    );

    if (included >= 0) {
      store.exRows.splice(included, 1);
    }
  },
});

export const patientCompletedChatsResponsesRequested = createSink<
  PatientDetailsCompletedChatsState,
  ReturnType<typeof PatientCompletedChatsResponsesRequested>
>({
  sources: [PatientCompletedChatsResponsesRequested],
  gate: ({ store, event }) =>
    !store.data.find(d => +d.checkup_id === event.payload.id).responses.length,
  async sink({ store, event, broadcast, getStore }) {
    const token = getStore('user').accessToken;
    const completedChatIndex = store.data.findIndex(
      d => +d.checkup_id === event.payload.id,
    );

    try {
      store.data[completedChatIndex].responsesLoading = true;

      const { data } = await axiosWithAuth(token, broadcast).get(
        `/api/vista/escalations/${event.payload.id}`,
      );

      if (!data.length) {
        store.data[completedChatIndex].responsesTableMessage =
          tableMessages.noResults;
      }

      store.data[completedChatIndex].responses = data;
    } catch (err) {
      store.data[completedChatIndex].responses = [];
      store.data[completedChatIndex].responsesTableMessage =
        tableMessages.serverError;
    } finally {
      store.data[completedChatIndex].responsesLoading = false;
    }
  },
});
