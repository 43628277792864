import { createProjector } from '@conversa/sink';
import { RouterState } from '../models';
import { Route } from 'vue-router';

export interface RouteProjectionsMap {
  'router.route': Route;
  'router.current-patient-id': number;
  'router.current-report-id': string;
}

export const routeProjector = createProjector<
  RouteProjectionsMap,
  RouterState
>();
