

































import { OrganizationSelected } from '@/organizations';
import { useIsDesktopListener, InlineLoader } from '@/shared';
import sink from '@/sink';
import { AncestrySelect } from '@conversa/bedazzled/src/selects';
import { computed, defineComponent, reactive, ref } from '@vue/composition-api';
import * as effects from './effects';
import Nav from './Nav.vue';
import ProfileButton from './ProfileButton.vue';

export default defineComponent({
  components: { Nav, AncestrySelect, InlineLoader, ProfileButton },
  setup() {
    const mobileNavOpen = ref(false);
    const isDesktop = useIsDesktopListener();
    const headerClasses = reactive({
      'mobile-nav-open': computed(() => {
        if (isDesktop.value) {
          return false;
        }

        return mobileNavOpen.value;
      }),
      'on-desktop': isDesktop,
    });

    effects.closeNavOnClickOutEffect(mobileNavOpen);

    return {
      headerClasses,
      loadingOrgs: sink.select('organizations.loadingOrgs'),
      organizations: sink.select('organizations.list'),
      selectedOrg: sink.select('organizations.selected'),
      orgMap: sink.select('organizations.map'),
      orgSelect: id => {
        sink.broadcast(OrganizationSelected({ id }));
      },
      menuButtonClicked: () => {
        mobileNavOpen.value = !mobileNavOpen.value;
      },
    };
  },
});
