import { createProjector } from '@conversa/sink';
import { AvailableReport, Report, ReportState } from '@/reporting/models';
import { DynamicFilter } from '@/shared/models/dynamic-filter.interface';
import { VuetifySelectOption } from '@/shared/models/select-options.interface';
import { ErrorState } from '@/shared';

export interface ReportsProjectionsMap {
  'reports.available': AvailableReport[];
  'reports.available-options': VuetifySelectOption[];
  'reports.available-count': number;
  'reports.available-error-state': ErrorState;
  'reports.available-loading': boolean;
  'report.filters': Record<string, string>;
  'report.filters-dirty': boolean;
  'report.filters-raw': DynamicFilter[];
  'report.id': string;
  'report.loading': boolean;
  'report.error-state': ErrorState;
  'report.url': Report;
}

export const reportsProjector = createProjector<
  ReportsProjectionsMap,
  ReportState
>();
