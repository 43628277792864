




import { defineComponent, computed } from '@vue/composition-api';
import { ColorStatus } from '../models';

export default defineComponent<{ status: ColorStatus }>({
  props: ['status'],
  setup(props) {
    return {
      icon: computed(
        () =>
          ({
            green: 'check_circle',
            yellow: 'error',
            red: 'cancel',
            none: null,
          }[props.status]),
      ),
    };
  },
});
