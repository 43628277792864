/* eslint-disable @typescript-eslint/camelcase */
import { OrganizationChanged } from '@/organizations/+state/events';
import {
  PatientsStatusChangeFailed,
  PatientStatusChanged,
  PatientStatusChangeRequested,
} from '@/patients/detail/header/+state/events';
import {
  EditPatientDialogSave,
  EditPatientFailed,
  PatientEdited,
  PatientDetailsFailed,
  PatientDetailsFailedDialogDismissed,
} from '@/patients/detail/summary/profile/+state/events';
import { isAxiosError } from '@/shared';
import { handleApiError } from '@/shared/api/set-store-error';
import { axiosWithAuth, isAuthError } from '@/shared/axios-with-auth';
import { UserState } from '@/user';
import { userStateKey } from '@/user/+state/store';
import { createSink } from '@conversa/sink';
import { PatientsDetailState } from '../models/state.interface';
import {
  PatientDetailMounted,
  PatientDetailUnmounted,
  PatientDetailFetched,
} from './events';
import { patientsDetailInitialState } from './store';
import router from '@/router';
import { DeeplinkFetched } from '@/+state';
import { DialogInstance } from '@conversa/bedazzled/src/dialog';

export const patientStatusSink = createSink<
  PatientsDetailState,
  ReturnType<typeof PatientStatusChangeRequested>
>({
  sources: [PatientStatusChangeRequested],
  async sink({ store, event, broadcast, getStore, select }) {
    const userStore = getStore(userStateKey) as UserState;
    const patientId = select('router.current-patient-id').value;

    if (!patientId) return;

    try {
      store.statusLoading = true;
      store.error = null;

      await axiosWithAuth(userStore.accessToken, broadcast).post(
        `/api/vista/patients/${patientId}/status`,
        {
          status: event.payload.status,
          userId: userStore.id,
        },
      );

      DialogInstance.close();
      broadcast(PatientStatusChanged());
    } catch (error) {
      if (!isAxiosError(error)) {
        if (!isAuthError(error)) {
          console.log(error);
        }
        return;
      }

      handleApiError(store, error);

      broadcast(PatientsStatusChangeFailed());
    } finally {
      store.statusLoading = false;
    }
  },
});

/**
 * This needs to load whenever the detail page loads, not when the patient profile is mounted
 */
export const patientDetailsRequested = createSink<
  PatientsDetailState,
  ReturnType<typeof PatientDetailMounted | typeof PatientStatusChanged>
>({
  sources: [PatientDetailMounted, PatientStatusChanged],
  async sink({ store, broadcast, getStore, select }) {
    const token = getStore('user').accessToken;
    const patientId = select('router.current-patient-id').value;

    try {
      store.profileLoading = true;
      store.error = null;

      const { data } = await axiosWithAuth(token, broadcast).get(
        `/api/vista/patients/${patientId}`,
      );

      store.data = data;
      broadcast(PatientDetailFetched());
    } catch (error) {
      if (!isAxiosError(error)) {
        if (!isAuthError(error)) {
          console.log(error);
        }

        return;
      }

      handleApiError(store, error);

      broadcast(
        PatientDetailsFailed({
          status: error.response.status,
          message: error.response.data,
        }),
      );
    } finally {
      store.profileLoading = false;
    }
  },
});

export const patientDetailsClearError = createSink<
  PatientsDetailState,
  ReturnType<typeof PatientDetailsFailedDialogDismissed>
>({
  sources: [PatientDetailsFailedDialogDismissed],
  sink({ store }) {
    store.error = patientsDetailInitialState.error;
  },
});

export const patientEdited = createSink<
  PatientsDetailState,
  ReturnType<typeof EditPatientDialogSave>
>({
  sources: [EditPatientDialogSave],
  async sink({ event, broadcast, store, getStore }) {
    const token = getStore('user').accessToken;
    const { patientId, ...payload } = event.payload;

    try {
      store.profileLoading = true;
      store.error = null;

      const { data } = await axiosWithAuth(token, broadcast).patch(
        `/api/vista/patients/${patientId}`,
        payload,
      );

      store.data = data;

      broadcast(PatientEdited());
      DialogInstance.close();
    } catch (error) {
      if (!isAxiosError(error)) {
        if (!isAuthError(error)) {
          console.log(error);
        }

        return;
      }

      handleApiError(store, error);

      broadcast(
        EditPatientFailed({
          payload: event.payload,
          error: store.error,
        }),
      );
    } finally {
      store.profileLoading = false;
    }
  },
});

export const clearPatientsDetailState = createSink<
  PatientsDetailState,
  ReturnType<typeof PatientDetailUnmounted | typeof OrganizationChanged>
>({
  sources: [PatientDetailUnmounted, OrganizationChanged],
  sink({ store }) {
    store.statusLoading = patientsDetailInitialState.statusLoading;
    store.profileLoading = patientsDetailInitialState.profileLoading;
    store.error = patientsDetailInitialState.error;
    store.data = patientsDetailInitialState.data;
  },
});

export const routeOnOrgChange = createSink<
  PatientsDetailState,
  ReturnType<typeof OrganizationChanged>
>({
  sources: [OrganizationChanged],
  sink() {
    const redirectRoutes = [
      'patientsDetailSummary',
      'patientActivity',
      'patientResponses',
      'patientCompletedChats',
    ];

    if (redirectRoutes.includes(router.currentRoute.name)) {
      router.push('/');
    }
  },
});

export const routeOnDeeplink = createSink<
  PatientsDetailState,
  ReturnType<typeof DeeplinkFetched>
>({
  sources: [DeeplinkFetched],
  sink({ event }) {
    const { patientIdNumeric, view } = event.payload;

    if (view === 'patientDetail') {
      router.push(`/patients/${patientIdNumeric}`);
    }
  },
});
