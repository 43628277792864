import { createProjector } from '@conversa/sink';
import { Organization, OrganizationsState, OrgMap } from '../models';
export interface OrganizationsProjectionsMap {
  'organizations.list': Organization[];
  'organizations.loadingOrgs': boolean;
  'organizations.selected': Organization;
  'organizations.map': OrgMap;
  'organizations.selected-org-child-ids': number[];
}

export const orgProjector = createProjector<
  OrganizationsProjectionsMap,
  OrganizationsState
>();
