enum TableMessages {
  preSearch = 'Perform a search to view results',
  noResults = 'Your search did not return any results',
  serverError = 'An unexpected error occurred. Please refresh the page to try again. If this persists, please contact your Customer Success representative',
}

export type TableMessage = TableMessages;

export const tableMessages: Record<
  keyof typeof TableMessages,
  TableMessages
> = {
  ...TableMessages,
};
