import { ProgramsState } from './../models';
import { Program } from '../models';
import {
  OrganizationChanged,
  OrganizationsLoaded,
} from '@/organizations/+state/events';
import { createSink } from '@conversa/sink';
import { axiosWithAuth } from '@/shared/axios-with-auth';
import { UserAccessTokenInvalid } from '@/user/+state/events';
import { programsInitialState } from './store';

export const loadPrograms = createSink<
  ProgramsState,
  ReturnType<typeof OrganizationsLoaded>
>({
  sources: [OrganizationsLoaded, OrganizationChanged],
  async sink({ store, getStore, broadcast }) {
    const token = getStore('user').accessToken;
    const selectedOrgId = getStore('organizations-state').selectedOrgId;
    store.loading = true;

    try {
      const programs = (
        await axiosWithAuth(token, broadcast).post<{ data: Program[] }>(
          '/api/vista/programs/search',
          // eslint-disable-next-line
          { filters: { org_id: selectedOrgId } },
        )
      ).data.data;

      store.programs = programs;
      store.loading = false;
    } catch (error) {
      store.loading = false;
    }
  },
});

export const clearProgramsState = createSink<
  ProgramsState,
  ReturnType<typeof UserAccessTokenInvalid>
>({
  sources: [UserAccessTokenInvalid],
  sink({ store }) {
    store.programs = programsInitialState.programs;
    store.selectedProgramId = programsInitialState.selectedProgramId;
    store.loading = programsInitialState.loading;
  },
});
