import { createStoreV2 } from '@conversa/sink';
import { ProgramsState } from '../models';

export const programsStateKey = 'programs-state';

export const programsInitialState: ProgramsState = {
  programs: null,
  selectedProgramId: null,
  loading: false,
};

export const programsStore = createStoreV2(
  programsStateKey,
  programsInitialState,
);
