import { action, payload } from 'ts-action';
import { UserProfile } from '../models';

export const UserIdTokenSet = action('[ component | User ] Id Token Set');

export const UserIdTokenInvalid = action(
  '[ component | User ] Id Token Invalid',
);

export const UserAccessTokenSet = action(
  '[ component | User ] Access Token Set',
);

export const UserAccessTokenInvalid = action(
  '[ component | User ] Access Token Invalid',
);

export const UserAccessTokenInvalidCancelled = action(
  '[ component | User ] Access Token Invalid Cancelled',
);

export const UserNoValidOrgs = action(
  '[ component | User ] No Valid Organizations',
);

export const UserAccessTokenMissing = action(
  '[ component | User ] Access Token Missing',
);

export const ProfileFetched = action(
  '[ api | /users/profile ] Profile Fetched',
  payload<{
    data: UserProfile;
  }>(),
);

export const ProfileFetchFailed = action(
  '[ api | /users/profile ] Fetch Failed',
);
export const ProfileUpdated = action(
  '[ api | /users/profile ] Profile Updated',
  payload<{
    data: UserProfile;
  }>(),
);

export const ProfileUpdateFailed = action(
  '[ api | /users/profile ] Update Failed',
);

/**
 * Used in @/user/+state/sinks.clearUserStoreError
 */
export const ProfileFetchErrorDialogDismissed = action(
  '[ component | Profile Fetch Error Dialog ] Dismissed',
);

export const LogoutButtonClicked = action(
  '[ component | App Bar ] Logout Button Clicked',
  payload<{ origin: 'profile' | 'session-expiring' }>(),
);

export const InactivityWarningThresholdMet = action(
  '[ timers ] Inactivity Warning Threshold Met',
);

export const InactivityThresholdMet = action(
  '[ timers ] Inactivity Treshold Met',
);

export const SessionEndingSoonThresholdMet = action(
  '[ timers ] Session Ending Soon Threshold Met',
);

export const InactivityTimerCancelled = action(
  '[ timers ] Inactivity Timer Cancelled',
);

export const SessionExpired = action('[ timers ] Session Expired');

export const EditUserDialogSave = action(
  '[ component | Edit User Dialog ] Saved',
  payload<{
    contactPhone: string;
    mobilePhone: string;
    alertThreshold: string;
    alertNotificationMethod: string;
  }>(),
);

export const UserProfileSet = action('[ api | /users/profile ] Profile Set');
