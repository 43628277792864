







import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  props: ['title', 'closeable'],
  emits: ['dialog-closed'],
});
