












import { defineComponent } from '@vue/composition-api';

export default defineComponent<{ title: string }>({
  props: ['title'],
});
