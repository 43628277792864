import { Organization, OrgMap } from '../models';
import { orgProjector } from './projections-map';

export const orgList = orgProjector<Organization[]>(
  'organizations.list',
  ({ store }) => store.orgs || [],
);

export const selectedOrgId = orgProjector<Organization>(
  'organizations.selected',
  ({ store }) => {
    return (
      store.orgs &&
      store.selectedOrgId &&
      store.orgs.find(org => org.id == store.selectedOrgId)
    );
  },
);

export const loadingOrgs = orgProjector<boolean>(
  'organizations.loadingOrgs',
  ({ store }) => store.loadingOrgs,
);

export const orgMap = orgProjector<OrgMap>('organizations.map', ({ store }) => {
  return store.orgMap;
});

export const selectedOrgChildrenIds = orgProjector<number[]>(
  'organizations.selected-org-child-ids',
  ({ store }) =>
    store.orgs
      .filter(org => org.ancestry.includes(store.selectedOrgId))
      .map(org => org.id),
);
