var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-select',{attrs:{"items":[
    {
      value: 'opted_in',
      text: 'Opted-In',
    },
    {
      value: 'opted_out',
      text: 'Opted-Out',
    } ],"value":_vm.status,"id":_vm.id,"name":"patient-status","label":"Patient Status","placeholder":"--","clearable":""},on:{"change":function($event){return _vm.$emit('change', $event)}}})}
var staticRenderFns = []

export { render, staticRenderFns }