import { StorageFilters } from './models';

type PersistFiltersParams<T> = {
  filter: StorageFilters;
  value: T;
  orgId: number;
};

export const persistFilters = <T>({
  filter,
  value,
  orgId,
}: PersistFiltersParams<T>) => {
  const filters = JSON.parse(localStorage.getItem('filters')) || {};

  filters[orgId]
    ? (filters[orgId][filter] = value)
    : (filters[orgId] = { [filter]: value });

  localStorage.setItem('filters', JSON.stringify(filters));
};

export const loadFilters = <T>(
  filter: StorageFilters,
  orgId: number,
): Partial<T> => {
  const filters = JSON.parse(localStorage.getItem('filters'));

  if (filters && filters[orgId] && filters[orgId][filter])
    return filters[orgId][filter];

  return {};
};
