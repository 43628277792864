import { action, payload } from 'ts-action';
import { Organization } from '../models';

export const OrganizationsLoadFailed = action(
  '[ api | vista/access/organizations ] Organizations Load Failed',
  payload<{
    status: number;
    // eslint-disable-next-line
    message: any;
  }>(),
);

export const OrganizationsLoaded = action(
  '[ api | organizations ] Organizations Loaded',
  payload<{ organizations: Organization[] }>(),
);

export const OrganizationChanged = action(
  '[ sink | organizations ] Organization Changed',
  payload<{ id: number }>(),
);

export const OrganizationSelected = action(
  '[ component | OrganizationSelector ] Organization Selected',
  payload<{ id: number }>(),
);
