import { ErrorState } from '@/shared/models/error-state.interface';
import { Survey } from '@/shared/models/survey.interface';
import { EnrollmentStatus } from '@/shared/models/enrollment-status.type';
import { createProjector } from '@conversa/sink';
import * as models from '../models';
import { capabilitiesStateKey } from '@/capabilities/+state/store';
import { CapabilitiesState } from '@conversa/bedazzled/src/models/capabilities-state.interface';
import { extractPartsFromDate } from '@/shared';
import { dateFormatter } from '../../../../../../../../shared/date-formatter';

export interface PatientsDetailSummaryEnrollmentsProjections {
  'patients.detail.summary.enrollments.active-count': number;
  'patients.detail.summary.enrollments': models.EnrollmentsProjection;
  'patients.detail.summary.surveys': models.AvailableSurvey[];
  'patients.detail.summary.selected-survey': Survey;
  'patients.detail.summary.selected-enrollment': models.Enrollment;
  'patients.detail.summary.surveys.cui-to-text-map': models.CuiMap;
  'patients.detail.summary.enrollments.loading': boolean;
  'patients.detail.summary.enrollments.error': ErrorState;
}

const projector = createProjector<
  PatientsDetailSummaryEnrollmentsProjections,
  models.PatientsDetailSummaryEnrollmentsState
>();

export const activeProgramCount = projector<number>(
  'patients.detail.summary.enrollments.active-count',
  ({ store }) => {
    return store.enrollments.filter(
      program => program.approval_status === 'approved',
    ).length;
  },
);

export const enrollmentsLoading = projector<boolean>(
  'patients.detail.summary.enrollments.loading',
  ({ store }) => store.loading,
);

export const enrollmentsError = projector<ErrorState>(
  'patients.detail.summary.enrollments.error',
  ({ store }) => store.error,
);

export const enrollments = projector<models.EnrollmentsProjection>(
  'patients.detail.summary.enrollments',
  ({ store, select, getStore }) => {
    // prettier-ignore
    const cuiMap = select('patients.detail.summary.surveys.cui-to-text-map')
      .value;
    const cStore = getStore(capabilitiesStateKey) as CapabilitiesState;
    return store.enrollments
      .filter(e => {
        const validStatus: EnrollmentStatus[] = ['approved', 'rejected'];
        return e.visible && validStatus.includes(e.approval_status);
      })
      .map(enrollment => {
        const enrollmentConceptAnswers = enrollment.concepts.reduce(
          (acc, curr) => {
            const concept = cuiMap[curr.cui];

            if (!concept) return acc;

            if (!acc[concept.text]) {
              acc[concept.text] = {
                cui: concept.cui,
                text: concept.text,
                answers: [],
              };
            }

            if (concept.type === 'date') {
              curr.value =
                (dateFormatter(
                  curr.value,
                  cStore.capabilities.international.datetimeFormat,
                  'date',
                ) as string) || null;
            }

            acc[concept.text].answers.push(concept.value || curr.value);

            return acc;
          },
          {},
        );

        return {
          name: enrollment.survey.name,
          description: enrollment.survey.description,
          surveyId: enrollment.survey.id,
          enrolledOn: dateFormatter(
            enrollment.created_at,
            cStore.capabilities.international.datetimeFormat,
            'date',
          ),
          updatedAt: dateFormatter(
            enrollment.updated_at,
            cStore.capabilities.international.datetimeFormat,
            'date',
          ),
          id: enrollment.id,
          visible: enrollment.visible,
          status: enrollment.approval_status,
          concepts: Object.keys(enrollmentConceptAnswers).map(
            key => enrollmentConceptAnswers[key],
          ),
        };
      })
      .sort((a, b) => {
        return a.name.toUpperCase() <= b.name.toUpperCase()
          ? -1
          : b.name.toUpperCase() < a.name.toUpperCase()
          ? 1
          : 0;
      });
  },
);

export const availableSurveys = projector<models.AvailableSurvey[]>(
  'patients.detail.summary.surveys',
  ({ store }) => {
    const ids = store.enrollments.map(enrollment => enrollment.survey.id);
    return store.surveys
      .filter(e => !ids.includes(+e.id))
      .map(survey => ({
        name: survey.name,
        description: survey.description,
        id: survey.id,
        visible: true,
      }))
      .sort((a, b) => {
        return a.name.toUpperCase() <= b.name.toUpperCase()
          ? -1
          : b.name.toUpperCase() < a.name.toUpperCase()
          ? 1
          : 0;
      });
  },
);

export const selectedSurvey = projector<Survey>(
  'patients.detail.summary.selected-survey',
  ({ store, select, getStore }) => {
    const i18nDateSetting = getStore(capabilitiesStateKey) as CapabilitiesState;
    const enrollment = select('patients.detail.summary.selected-enrollment')
      .value;

    const survey = store.surveys.find(
      survey => survey.id === store.selectedSurveyId,
    );

    const concepts = (survey?.concepts || []).map(concept => {
      const options = concept.options?.length
        ? concept.options.map(option => ({
            ...option,
            value: option.text,
            content: option.text,
          }))
        : concept.options;

      const newConcept = {
        ...concept,
        options,
        type: concept.type,
      };

      newConcept['value'] = '';

      // for the edit enrollment dialog
      // set the value property on each concept to pre-populate the form fields
      if (enrollment) {
        // not type 'checks' or 'mults'
        if (concept.cui) {
          const enrolledConcept = enrollment.concepts.find(
            enrollmentConcept => enrollmentConcept.cui === concept.cui,
          );

          if (concept.type === 'date') {
            const [year, month, day] = extractPartsFromDate(
              enrolledConcept?.value,
              i18nDateSetting.capabilities.international.datetimeFormat,
            );

            enrolledConcept.value = enrolledConcept?.value
              ? new Date(Date.UTC(Number(year), Number(month) - 1, Number(day)))
                  .toISOString()
                  .slice(0, -1)
              : null;
          }

          newConcept['value'] = enrolledConcept?.value || '';
        } else if (concept.options?.length) {
          const enrolledConceptCuis = enrollment.concepts.map(
            concept => concept.cui,
          );

          // type 'checks' uses an array of concept ids as its value
          // type 'mult' uses a single concept id as its value
          const conceptIds: number[] = [];
          for (const option of concept.options) {
            if (enrolledConceptCuis.includes(option.cui)) {
              conceptIds.push(option.id);

              if (concept.type === 'mult') break;
            }
          }

          newConcept['value'] =
            concept.type === 'mult' ? conceptIds[0] : conceptIds;
        }
      }

      return newConcept;
    });

    return {
      ...survey,
      concepts,
    };
  },
);

export const selectedEnrollment = projector<models.Enrollment>(
  'patients.detail.summary.selected-enrollment',
  ({ store }) => {
    return store.enrollments.find(
      program => program.id === store.selectedEnrollmentId,
    );
  },
);

export const cuiTextValueMap = projector<models.CuiMap>(
  'patients.detail.summary.surveys.cui-to-text-map',
  ({ store }) => {
    return store.surveys.reduce((acc, curr) => {
      if (!curr.concepts) return acc;

      curr.concepts.forEach(concept => {
        if (concept.cui) {
          /**
           * The value should come from the EnrollmentConcept.value,
           * The user should have entered something into a text/number/date input
           */
          acc[concept.cui] = {
            cui: concept.cui,
            text: concept.text,
            value: null,
            type: concept.type,
          };
        } else if (concept.options) {
          /**
           * EnrollmentConcepts that are options of a parent don't have a value itself
           * We pick the option.text here for display later as the "value" of the concept
           */
          concept.options.forEach(option => {
            acc[option.cui] = {
              cui: option.cui,
              text: concept.text,
              value: option.text,
              type: concept.type,
            };
          });
        }
      });

      return acc;
    }, {});
  },
);
