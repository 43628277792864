import { tableMessages } from '../../shared/empty-table-messages';
import { createStoreV2 } from '@conversa/sink';
import { PatientEngagementsState } from '../models';

export const patientEngagementsStateKey = 'patient-engagements-state';

export const patientEngagementsInitialState: PatientEngagementsState = {
  data: [],
  count: null,
  error: null,
  loading: false,
  page: 1,
  sortCol: 'lastScheduledChatDate',
  sortDir: 'desc',
  filtersDirty: false,
  tableMessage: tableMessages.preSearch,
  filters: {
    enrollmentDateFrom: '',
    enrollmentDateTo: '',
    patientStatus: null,
    programIds: [],
    providerIds: [],
    showTestPatients: null,
    lastChatStatus: null,
  },
};

export const patientEngagementStore = createStoreV2(
  patientEngagementsStateKey,
  patientEngagementsInitialState,
);
