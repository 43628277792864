import { axiosWithAuth } from '@/shared/axios-with-auth';
import { isAxiosError } from '@/shared';

export async function downloadFromApi(
  endpointUrl,
  payload,
  store,
  token,
  broadcast,
  failedAction,
) {
  try {
    const { data, headers } = await axiosWithAuth(token, broadcast).post(
      endpointUrl,
      payload,
    );

    const today = new Date().toISOString().substring(0, 10);
    let filename = `conversa-${today}.csv`;

    const disposition = headers['content-disposition'];
    if (disposition && disposition.indexOf('attachment') !== -1) {
      const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      const matches = filenameRegex.exec(disposition);
      if (matches !== null && matches[1]) {
        filename = matches[1].replace(/['"]/g, '');
      }
    }

    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
  } catch (error) {
    if (!isAxiosError(error)) {
      console.log(error);
      return;
    }

    broadcast(
      failedAction({
        payload,
        status: error.response.status,
        message: error.response.data,
      }),
    );
  }
}
