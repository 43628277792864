import { createStoreV2 } from '@conversa/sink';
import { patientsDetailSessionsInitialState } from '@conversa/bedazzled/src/models/patient-session-state';

export { patientsDetailSessionsInitialState };

export const patientsDetailSessionsStateKey =
  'patients-detail-summary-sessions-state';

export const patientsDetailSessionStore = createStoreV2(
  patientsDetailSessionsStateKey,
  patientsDetailSessionsInitialState,
);
