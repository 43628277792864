import { createModule } from '@conversa/sink';
import * as projections from './projections';
import * as sinks from './sinks';
import { userStore as store } from './store';
import { UserState } from '../models';

export * from './projections-map';
export * from './events';
export const userModule = createModule<UserState>({
  projections,
  sinks,
  store,
});
