import { TableMessage } from './../../shared/empty-table-messages';
import { ResponsesSortCol, SortDir } from '@/shared';
import { createProjector } from '@conversa/sink';
import * as models from '../models';

export interface ResponsesProjectionsMap {
  'responses.firstName': string;
  'responses.filters': models.ResponsesFilters;
  'responses.loading': boolean;
  'responses.table': models.ResponsesTable;
  'responses.search-payload': models.ResponsesSearchFiltersPayload;
  'responses.sort-col': ResponsesSortCol;
  'responses.sort-dir': SortDir;
  'responses.filters-dirty': boolean;
  'responses.count': number;
  'responses.page-number': number;
  'responses.tableMessage': TableMessage;
}

export const responsesProjector = createProjector<
  ResponsesProjectionsMap,
  models.ResponsesState
>();
