import { RouterState } from '../models';
import { createStoreV2 } from '@conversa/sink';

export const routerStateKey = 'router';

export const routerInitialState: RouterState = {
  route: null,
};

export const routerStore = createStoreV2(routerStateKey, routerInitialState);
