





































import { defineComponent, ref, PropType, watch } from '@vue/composition-api';
import { VuetifySelectOption } from '@conversa/bedazzled/src/models/select-options.interface';

export default defineComponent({
  props: {
    options: {
      type: Array as PropType<VuetifySelectOption[]>,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    inputValue: {
      type: Array as PropType<number[]>,
      required: true,
    },
    showSelectAll: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  emits: ['change'],
  setup(props, context) {
    const comboboxOptions = ref<VuetifySelectOption[]>(props.options);
    const optionsSelected = ref<(string | number)[]>(props.inputValue);
    const selectAllSelected = ref(false);

    watch(
      () => props.inputValue,
      currentValue => {
        if (currentValue.length) return;

        optionsSelected.value = [];
        selectAllSelected.value = false;
      },
    );

    const emitChange = () => {
      context.emit('change', optionsSelected.value);
    };

    let debouncer = null;

    const searchOption = (value: string) => {
      if (debouncer) clearTimeout(debouncer);

      if (!value) {
        comboboxOptions.value = props.options;
        return;
      }

      debouncer = setTimeout(() => {
        const newValue = new RegExp(value.trim(), 'ig');
        comboboxOptions.value = props.options.filter(option =>
          option.text.match(newValue),
        );
      }, 150);
    };

    const toggleSelection = (hasSelectedAll: boolean) => {
      if (!hasSelectedAll) {
        optionsSelected.value = [];
      } else {
        optionsSelected.value = comboboxOptions.value.map(({ value }) => value);
      }

      emitChange();
    };

    return {
      comboboxOptions,
      optionsSelected,
      selectAllSelected,
      toggleSelection,
      searchOption,
      emitChange,
    };
  },
});
