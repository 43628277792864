import { createModule } from '@conversa/sink';
import { PatientEngagementsState } from '../models';
import * as projections from './projections';
import * as sinks from './sinks';
import { patientEngagementStore as store } from './store';

export * from './projections-map';
export * from './events';
export const patientEngagementModule = createModule<PatientEngagementsState>({
  projections,
  sinks,
  store,
});
