import { Deeplink, deeplinkViewList } from '@/shared/models';
import { isPositiveInteger } from '@/shared/utils';

export const deeplinkIdCookieName = 'vista_deeplink_id';
export const partnerAuthCookieName = 'vista_partner_auth';

/**
 * Validates a deeplink payload; primarily to avoid checking for invalid struct or values in every consumer of `DeeplinkFetched` and
 * assert expected `view` values.
 *
 * @param deeplink
 */
export function isDeeplinkValid(deeplink: Deeplink): boolean {
  return (
    !!deeplink &&
    isPositiveInteger(deeplink.org?.idNumeric) &&
    isPositiveInteger(deeplink.patientIdNumeric) &&
    deeplinkViewList.includes(deeplink.view)
  );
}
