

















import DialogTitle from '@/dialog/DialogTitle.vue';
import { userModule } from '@/user';
import { defineComponent } from '@vue/composition-api';
import Alert from './Alert.vue';

export default defineComponent({
  components: { DialogTitle, Alert },
  setup() {
    return {
      error: userModule.injectStore().error?.message,
    };
  },
});
