import { createModule } from '@conversa/sink';
import { ReportState } from '../models/report-state';
import * as projections from './projections';
import * as sinks from './sinks';
import { reportStore as store } from './store';

export * from './events';
export const reportModule = createModule<ReportState>({
  projections,
  sinks,
  store,
});
