import { createStoreV2 } from '@conversa/sink';
import { ProvidersState } from '../models';

export const providersStateKey = 'providers-state';

export const providersInitialState: ProvidersState = {
  providers: null,
  loadingProviders: false,
};

export const providersStore = createStoreV2(
  providersStateKey,
  providersInitialState,
);
