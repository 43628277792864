import { createStoreV2 } from '@conversa/sink';
import { OrganizationsState } from '../models';

export const organizationsStateKey = 'organizations-state';

export const organizationsInitialState: OrganizationsState = {
  orgs: null,
  selectedOrgId: null,
  loadingOrgs: false,
  orgMap: {},
};

export const organizationsStore = createStoreV2(
  organizationsStateKey,
  organizationsInitialState,
);
