




















import sink from '@/sink';
import { defineComponent } from '@vue/composition-api';
import DialogTitle from '@/dialog/DialogTitle.vue';
import { DialogInstance } from '@conversa/bedazzled/src/dialog';

export default defineComponent({
  components: { DialogTitle },
  setup() {
    return {
      error: sink.select('report.error-state'),
      dismiss: () => DialogInstance.close(),
    };
  },
});
