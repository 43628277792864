import { tableMessages } from './../../shared/empty-table-messages';
import { createStoreV2 } from '@conversa/sink';
import { ActivitiesState } from '../models';

export const activitiesStateKey = 'activities-state';

export const activitiesInitialState: ActivitiesState = {
  data: [],
  error: null,
  count: null,
  loading: false,
  page: 1,
  sortCol: 'activityDate',
  sortDir: 'asc',
  filtersDirty: false,
  tableMessage: tableMessages.preSearch,
  filters: {
    activityDateFrom: '',
    activityDateTo: '',
    clinicPatientCode: null,
    firstName: null,
    lastName: null,
    patientStatus: null,
    showTestPatients: false,
    providerIds: [],
    orgId: null,
    activity: [],
  },
};

export const activitiesStore = createStoreV2(
  activitiesStateKey,
  activitiesInitialState,
);
