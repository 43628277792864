




import { ErrorDialog } from '@/shared/components';
import sink from '@/sink';
import { ProfileFetchErrorDialogDismissed } from '@/user/+state';
import { DialogInstance } from '@conversa/bedazzled/src/dialog';
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  components: { ErrorDialog },
  setup() {
    return {
      error: sink.select('user.error-message'),
      dismissed: () => {
        sink.broadcast(ProfileFetchErrorDialogDismissed());
        DialogInstance.close();
      },
    };
  },
});
