import { createModule } from '@conversa/sink';
import { EscalationsState } from '../models';
import * as projections from './projections';
import * as sinks from './sinks';
import { escalationsStore as store } from './store';

export * from './projections-map';
export * from './events';
export const escalationsModule = createModule<EscalationsState>({
  projections,
  sinks,
  store,
});
