import { action, payload } from 'ts-action';
import { Report } from '@/reporting/models/report';

export const ReportActionsMounted = action(
  '[ component | Report Actions ] Report Action Mounted',
);

export const ReportSelected = action(
  '[ component | Report ] Report Selected',
  payload<{ reportId: string }>(),
);

export const ReportLoaded = action(
  '[ component | Report ] Report Loaded',
  payload<Report>(),
);

export const ReportFiltersChanged = action(
  '[ component | Report Filters ] Filter Changed',
  payload<{ input: string; value: string }>(),
);

export const ReportFiltersCleared = action(
  '[ component | Report Filters ] Filters Cleared',
);

export const ReportRunRequested = action(
  '[ component | Report ] Run Requested',
  payload<{ runType: 'reportChange' | 'filterUpdate' }>(),
);

export const ReportLoadFailed = action(
  '[ api | vista/reports ] Report Load Failed',
  payload<{
    status: number;
    // eslint-disable-next-line
    message: any;
  }>(),
);

export const ReportsAvailableRequested = action(
  '[ api | vista/reports ] Reports Available Requested',
);

export const ReportsAvailableLoadFailed = action(
  '[ api | vista/reports ] Reports Available Load Failed',
  payload<{
    status: number;
    // eslint-disable-next-line
    message: any;
  }>(),
);
