


















import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  props: {
    loading: {
      type: Boolean,
      required: true,
    },
    content: {
      type: String,
    },
    lod: {
      type: Boolean,
      default: false,
    },
  },
});
