import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import { Iconfont } from 'vuetify/types/services/icons';

Vue.use(Vuetify);

const themeColors = {
  anchor: '#46AFF2',
  warning: '#F23D3D',
  tertiary: '#4ba6dd',
};

const opts = {
  theme: {
    themes: {
      light: {
        ...themeColors,
      },
    },
  },
  icons: {
    iconfont: 'mdiSvg' as Iconfont,
  },
};

export default new Vuetify(opts);
