import { ActivitiesSortCol, Payload, SortDir } from '@/shared';
import { action, payload } from 'ts-action';
import { ActivitiesFilters, ActivitiesSearchFiltersPayload } from '../models';

export const ActivitiesFilterChanged = action(
  '[ component | Activities Filters ] Filter Changed',
  payload<{ input: keyof ActivitiesFilters; value: string }>(),
);

export const ActivitiesFiltersRestored = action(
  '[ component | Activities Filters ] Filters Restored',
);

export const ActivityFiltersCleared = action(
  '[ component | ActivityFilter ] Clear All',
);

export const ActivitiesFilterSearched = action(
  '[ component | ActivitiesFilter ] Searched',
  payload<{ origin: 'search' | 'sort'; resetPage?: boolean }>(),
);

export const ActivitiesSearchFailed = action(
  '[ api | vista/activities/search ] Activities Search Failed',
  payload<{
    payload: Payload<ActivitiesSearchFiltersPayload>;
    status: number;
    // eslint-disable-next-line
    message: any;
  }>(),
);

export const ActivitiesSearchFailedDialogDismissed = action(
  '[ component | ActivitiesSearchFailedDialog ] Dismissed',
);

export const ActivitiesTableSorted = action(
  '[ component | Activities Table ] Table Sorted',
  payload<{ col: ActivitiesSortCol; dir: SortDir }>(),
);

export const ActivitiesPageUpdated = action(
  '[ component | Activities Table ] Page Updated',
  payload<{ page: number }>(),
);

export const ActivitiesExportClicked = action(
  '[ component | Activities Page ] Export Clicked',
);

export const ActivitiesExportFailed = action(
  '[ api | vista/activities/export ] Activities Export Failed',
  payload<{
    payload: null;
    status: number;
    message: unknown;
  }>(),
);
