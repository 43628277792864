import { OrganizationChanged } from '@/organizations/+state/events';
import { patientsStateKey } from '@/patients/+state/store';
import { PatientDetailUnmounted } from '@/patients/detail/+state/events';
import { PatientStatusChanged } from '@/patients/detail/header/+state/events';
import {
  EnrollmentChangeStatusSucceeded,
  PatientEnrollmentSuccessful,
} from '@/patients/detail/summary/enrollments/+state/events';
import { PatientsState } from '@/patients/models';
import { isAxiosError } from '@/shared';
import { handleApiError } from '@/shared/api/set-store-error';
import { axiosWithAuth, isAuthError } from '@/shared/axios-with-auth';
import { UserState } from '@/user';
import { userStateKey } from '@/user/+state/store';
import { createSink } from '@conversa/sink';
import { PatientsDetailSummarySessionsState } from '../models/state.interface';
import {
  FetchSessions,
  PatientResendChatLinkRequested,
  PatientResendChatLinkRequestFailed,
  PatientResendChatLinkRequestQueued,
  PatientSessionInformationMounted,
  PatientsSessionsFailed,
  SessionPageUpdated,
  ViewHistoricUpdated,
  ResendChatDialogDismissed,
} from './events';
import { patientsDetailSessionsInitialState } from './store';
import { DialogInstance } from '@conversa/bedazzled/src/dialog';

export const patientSessionsRequested = createSink<
  PatientsDetailSummarySessionsState,
  ReturnType<
    | typeof PatientEnrollmentSuccessful
    | typeof PatientSessionInformationMounted
    | typeof PatientStatusChanged
    | typeof EnrollmentChangeStatusSucceeded
    | typeof FetchSessions
  >
>({
  sources: [
    PatientEnrollmentSuccessful,
    PatientSessionInformationMounted,
    PatientStatusChanged,
    EnrollmentChangeStatusSucceeded,
    FetchSessions,
  ],
  async sink({ store, broadcast, getStore, select }) {
    const userStore: UserState = getStore(userStateKey);
    const patientsStore: PatientsState = getStore(patientsStateKey);
    // prettier-ignore
    const patientId = select('router.current-patient-id').value || patientsStore.newlyAddedPatient.id;

    try {
      store.loading = true;
      store.error = null;

      const { data } = await axiosWithAuth(
        userStore.accessToken,
        broadcast,
      ).get(`/api/vista/patients/${patientId}/sessions`, {
        params: { limit: 0, scope: store.viewHistoric ? 'past' : 'future' },
      });

      store.data = data.data;
    } catch (error) {
      if (!isAxiosError(error)) {
        if (!isAuthError(error)) {
          console.log(error);
        }
        return;
      }

      handleApiError(store, error);

      broadcast(
        PatientsSessionsFailed({
          error: store.error,
        }),
      );
    } finally {
      store.loading = false;
    }
  },
});

export const viewHistoricUpdated = createSink<
  PatientsDetailSummarySessionsState,
  ReturnType<typeof ViewHistoricUpdated>
>({
  sources: [ViewHistoricUpdated],
  sink({ store, event, broadcast }) {
    store.viewHistoric = event.payload.viewHistoric;
    store.page = 1;
    broadcast(FetchSessions());
  },
});

export const sessionPageUpdated = createSink<
  PatientsDetailSummarySessionsState,
  ReturnType<typeof SessionPageUpdated>
>({
  sources: [SessionPageUpdated],
  sink({ store, event }) {
    store.page = event.payload.page;
  },
});

export const patientResendChatLinkRequested = createSink<
  PatientsDetailSummarySessionsState,
  ReturnType<typeof PatientResendChatLinkRequested>
>({
  sources: [PatientResendChatLinkRequested],
  async sink({ store, select, broadcast, getStore }) {
    const token = getStore('user').accessToken;
    const patientId = select('router.current-patient-id').value;
    try {
      store.loading = true;
      store.error = null;
      await axiosWithAuth(token, broadcast).post(
        `/api/vista/patients/${patientId}/resend_communication`,
      );

      broadcast(PatientResendChatLinkRequestQueued());
      DialogInstance.close();
    } catch (error) {
      if (!isAxiosError(error)) {
        if (!isAuthError(error)) {
          console.log(error);
        }
        return;
      }

      handleApiError(store, error);

      broadcast(
        PatientResendChatLinkRequestFailed({
          error: store.error,
        }),
      );
    } finally {
      store.loading = false;
    }
  },
});

export const clearPatientsDetailSummarySessionsState = createSink<
  PatientsDetailSummarySessionsState,
  ReturnType<typeof PatientDetailUnmounted | typeof OrganizationChanged>
>({
  sources: [PatientDetailUnmounted, OrganizationChanged],
  sink({ store }) {
    store.data = patientsDetailSessionsInitialState.data;
    store.error = patientsDetailSessionsInitialState.error;
    store.loading = patientsDetailSessionsInitialState.loading;
    store.page = patientsDetailSessionsInitialState.page;
  },
});

export const resendChatDialogDismissed = createSink<
  PatientsDetailSummarySessionsState,
  ReturnType<typeof ResendChatDialogDismissed>
>({
  sources: [ResendChatDialogDismissed],
  sink({ store }) {
    store.error = patientsDetailSessionsInitialState.error;
  },
});
