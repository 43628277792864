import { tableMessages } from './../../shared/empty-table-messages';
import { createStoreV2 } from '@conversa/sink';
import { EscalationsState } from '../models';

export const escalationsStateKey = 'escalations-state';

export const escalationsInitialState: EscalationsState = {
  data: [],
  count: null,
  error: null,
  loading: false,
  page: 1,
  sortCol: 'chatDate',
  sortDir: 'desc',
  filtersDirty: false,
  tableMessage: tableMessages.preSearch,
  exRows: [],
  filters: {
    chatStatus: [],
    enrollmentDateFrom: '',
    enrollmentDateTo: '',
    patientStatus: null,
    programIds: [],
    providerIds: [],
    showTestPatients: null,
  },
};

export const escalationsStore = createStoreV2(
  escalationsStateKey,
  escalationsInitialState,
);
