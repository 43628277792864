import { axiosWithAuth } from '@/shared/axios-with-auth';
import sink from '@/sink';
import { UserAccessTokenInvalid, UserAccessTokenSet } from '@/user';
import { createSink } from '@conversa/sink';
import { getOrgId, removeOrgId, setOrgId } from '@/shared';
import { Organization, OrganizationsState } from '../models';
import {
  OrganizationChanged,
  OrganizationSelected,
  OrganizationsLoaded,
  OrganizationsLoadFailed,
} from './events';
import { DeeplinkFetched } from '@/+state';

export const loadOrgs = createSink<
  OrganizationsState,
  ReturnType<typeof UserAccessTokenSet>
>({
  sources: [UserAccessTokenSet],
  async sink({ store, getStore, broadcast }) {
    const token = getStore('user').accessToken;
    store.loadingOrgs = true;

    try {
      const organizations = (
        await axiosWithAuth(token, broadcast).get<Organization[]>(
          '/api/vista/access/organizations',
        )
      ).data;

      if (Array.isArray(organizations)) {
        organizations.sort((a, b) => {
          const lowerA = a.name.toLowerCase();
          const lowerB = b.name.toLowerCase();

          if (lowerA < lowerB) {
            return -1;
          }
          if (lowerA > lowerB) {
            return 1;
          }

          return 0;
        });
      }

      const orgMap = organizations.reduce((acc, curr) => {
        const newAcc = acc;
        if (!curr.ancestor_id) {
          //checks to find root of organization ancestry which has null as its ancestor_id.
          //replacing with 0 so it remains an option as the orgMap object is created while not interfearing with other org id's.
          newAcc[0] = organizations.filter(org => !org.ancestor_id);
        }
        return {
          ...newAcc,
          [curr.id]: organizations.filter(org => org.ancestor_id == curr.id),
        };
      }, {});

      store.orgs = organizations;
      store.selectedOrgId = orgMap[1]
        ? parseInt(getOrgId()) || 1
        : organizations[0].id;
      store.orgMap = orgMap;
      sink.broadcast(OrganizationsLoaded({ organizations }));
      store.loadingOrgs = false;
    } catch (error) {
      store.loadingOrgs = false;

      if (error.response?.status !== 401)
        sink.broadcast(
          OrganizationsLoadFailed({
            status: error.response?.status,
            message: error.message,
          }),
        );
    }
  },
});

export const setOrganization = createSink<
  OrganizationsState,
  ReturnType<typeof OrganizationSelected>
>({
  sources: [OrganizationSelected],
  sink({ event, store }) {
    store.selectedOrgId = event.payload.id;
    setOrgId(event.payload.id.toString());
    sink.broadcast(OrganizationChanged({ id: event.payload.id }));
  },
});

export const clearOrganizationsOnInvalidUserToken = createSink<
  OrganizationsState,
  ReturnType<typeof UserAccessTokenInvalid>
>({
  sources: [UserAccessTokenInvalid],
  async sink({ store }) {
    store.orgs = null;
    store.selectedOrgId = null;
    store.loadingOrgs = false;
    store.orgMap = {};
    removeOrgId();
  },
});

export const setOrganizationFromDeeplink = createSink<
  OrganizationsState,
  ReturnType<typeof DeeplinkFetched>
>({
  sources: [DeeplinkFetched],
  sink({ event, store }) {
    const dlOrgId = event.payload.org.idNumeric;

    store.selectedOrgId = dlOrgId;
    setOrgId(dlOrgId.toString());
    sink.broadcast(OrganizationChanged({ id: dlOrgId }));
  },
});
