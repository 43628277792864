import { createModule } from '@conversa/sink';
import * as projections from './projections';
import * as sinks from './sinks';
import { PatientsDetailSummarySessionsState } from '../models';
import { patientsDetailSessionStore as store } from './store';

export const patientsDetailSummarySessionsModule = createModule<
  PatientsDetailSummarySessionsState
>({
  projections,
  sinks,
  store,
});
