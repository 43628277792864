





























import { defineComponent, PropType, ref, watch } from '@vue/composition-api';
import { SelectOptions } from '@conversa/bedazzled/src/models/select-options.interface';
export default defineComponent({
  props: {
    options: {
      type: Array as PropType<SelectOptions[]>,
      required: true,
    },
    inputValue: {
      type: Array as PropType<string[]>,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
  },
  emits: ['change'],
  setup(props) {
    const optionsSelected = ref<string[]>(props.inputValue);

    watch(
      () => props.inputValue,
      currentValue => {
        optionsSelected.value = currentValue;
      },
    );

    return {
      optionsSelected,
    };
  },
});
