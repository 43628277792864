






















import { defineComponent } from '@vue/composition-api';

export default defineComponent<{
  status: 'opted-in' | 'opted-out';
  id: string;
}>({
  props: ['status', 'id'],
});
