import { Provider, ProvidersState } from './../models';
import { VuetifySelectOption } from '@/shared/models/select-options.interface';
import { createProjector } from '@conversa/sink';

export interface ProvidersProjectionsMap {
  'providers.loading': boolean;
  'providers.providers': Provider[];
  'providers.select-options': VuetifySelectOption[];
}

export const providersProjector = createProjector<
  ProvidersProjectionsMap,
  ProvidersState
>();
