


























































import sink from '@/sink';
import { Alert } from '@/shared';
import { computed, defineComponent, ref } from '@vue/composition-api';
import { LogoutButtonClicked } from '../../+state/events';
import ProfileAccountInfo from './ProfileAccountInfo.vue';
import ProfileUserSettings from './ProfileUserSettings.vue';
import DialogTitle from '@/dialog/DialogTitle.vue';
import { DialogInstance } from '@conversa/bedazzled/src/dialog';

export default defineComponent({
  components: {
    ProfileAccountInfo,
    ProfileUserSettings,
    Alert,
    DialogTitle,
  },
  setup() {
    const template = ref<'user-settings' | 'account-info'>('user-settings');
    const formStatusChanged = ref(false);
    const isFormValid = ref(true);

    return {
      isFormValid,
      template,
      formStatusChanged,
      currentTemplate: computed(
        () =>
          (template.value === 'user-settings' && ProfileUserSettings) ||
          ProfileAccountInfo,
      ),
      showSaveButton: computed(
        () => template.value === 'user-settings' && formStatusChanged.value,
      ),
      error: sink.select('user.error-message'),
      cancel: () => DialogInstance.close(),
      logout: sink.lazyBroadcast(LogoutButtonClicked({ origin: 'profile' })),
    };
  },
});
