import sanitizeHtml from 'sanitize-html';
import { NewPatientSavedBeforeEnroll } from '@/patients/+state/events';
import { patientsStateKey } from '@/patients/+state/store';
import { PatientDetailUnmounted } from '@/patients/detail/+state/events';
import { PatientsState } from '@/patients/models';
import { isAxiosError } from '@/shared';
import { handleApiError } from '@/shared/api/set-store-error';
import { axiosWithAuth, isAuthError } from '@/shared/axios-with-auth';
import { UserState } from '@/user';
import { userStateKey } from '@/user/+state/store';
import { createSink } from '@conversa/sink';
import { isType } from 'ts-action';
import { PatientsDetailSummaryEnrollmentsState } from '../models';
import {
  EnrollmentChangeStatusCancelClicked,
  EnrollmentChangeStatusClicked,
  EnrollmentChangeStatusFailed,
  EnrollmentChangeStatusSucceeded,
  EnrollmentCreateDialogBackClicked,
  EnrollmentDetailsDialogCancelClicked,
  EnrollmentEditClicked,
  EnrollmentsFetchFailed,
  EnrollmentSubmitted,
  EnrollmentUpdateFailed,
  EnrollmentUpdateSubmitted,
  EnrollmentUpdateSucceeded,
  EnrollPatientClicked,
  PatientEnrollmentFailed,
  PatientEnrollmentInformationMounted,
  PatientEnrollmentSuccessful,
  SurveySelected,
  SurveysFetchFailed,
  UnenrollRequested,
} from './events';
import { patientDetailSummaryEnrollmentsInitialState } from './store';
import { OrganizationChanged } from '@/organizations/+state/events';
import { DialogInstance } from '@conversa/bedazzled/src/dialog';

export const enrollmentsRequestedSink = createSink<
  PatientsDetailSummaryEnrollmentsState,
  ReturnType<typeof PatientEnrollmentInformationMounted>
>({
  sources: [
    PatientEnrollmentInformationMounted,
    EnrollmentUpdateSucceeded,
    EnrollmentChangeStatusSucceeded,
    PatientEnrollmentSuccessful,
  ],
  async sink({ store, broadcast, getStore, select }) {
    const userStore: UserState = getStore(userStateKey);
    const patientId = select('router.current-patient-id').value;

    if (!patientId) return;

    try {
      store.loading = true;
      store.error = null;

      const { data } = await axiosWithAuth(
        userStore.accessToken,
        broadcast,
      ).get(`/api/vista/patients/${patientId}/enrollments`, {
        params: {
          /* eslint-disable-next-line @typescript-eslint/camelcase */
          render_concepts: true,
        },
      });

      store.enrollments = data.data;
    } catch (error) {
      if (!isAxiosError(error)) {
        if (!isAuthError(error)) {
          console.log(error);
        }

        return;
      }

      handleApiError(store, error);

      broadcast(
        EnrollmentsFetchFailed({
          error: store.error,
        }),
      );
    } finally {
      store.loading = false;
    }
  },
});

export const surveysRequestedSink = createSink<
  PatientsDetailSummaryEnrollmentsState,
  ReturnType<
    | typeof PatientEnrollmentInformationMounted
    | typeof NewPatientSavedBeforeEnroll
  >
>({
  sources: [PatientEnrollmentInformationMounted, NewPatientSavedBeforeEnroll],
  async sink({ store, broadcast, getStore, event, select }) {
    const token = getStore('user').accessToken;
    let patientId;

    if (isType(event, NewPatientSavedBeforeEnroll)) {
      patientId = event.payload.id;
    } else {
      patientId = select('router.current-patient-id').value;
    }

    /* eslint-disable @typescript-eslint/camelcase */
    try {
      store.loading = true;
      store.error = null;

      const { data } = await axiosWithAuth(token, broadcast).get(
        `/api/vista/patients/${patientId}/surveys`,
      );

      store.surveys = data.data;
      store.surveys = data.data?.map(survey => {
        survey.description = sanitizeHtml(survey.description ?? '', {
          allowedTags: ['br', 'hr', 'em', 'strong'],
        });
        return survey;
      });
    } catch (error) {
      if (!isAxiosError(error)) {
        if (!isAuthError(error)) {
          console.log(error);
        }
        return;
      }

      handleApiError(store, error);

      broadcast(
        SurveysFetchFailed({
          error: store.error,
        }),
      );
    } finally {
      store.loading = false;
    }
  },
});

export const enrollPatientClickedSink = createSink<
  PatientsDetailSummaryEnrollmentsState,
  ReturnType<typeof EnrollPatientClicked>
>({
  sources: [EnrollPatientClicked],
  sink({ store }) {
    store.selectedEnrollmentId =
      patientDetailSummaryEnrollmentsInitialState.selectedEnrollmentId;
  },
});

export const surveySelectedSink = createSink<
  PatientsDetailSummaryEnrollmentsState,
  ReturnType<
    | typeof SurveySelected
    | typeof EnrollmentEditClicked
    | typeof EnrollmentChangeStatusClicked
  >
>({
  sources: [
    SurveySelected,
    EnrollmentEditClicked,
    EnrollmentChangeStatusClicked,
  ],
  sink({ store, event }) {
    store.selectedSurveyId = event.payload.id;
  },
});

export const enrollmentSelectedSink = createSink<
  PatientsDetailSummaryEnrollmentsState,
  ReturnType<
    typeof EnrollmentEditClicked | typeof EnrollmentChangeStatusClicked
  >
>({
  sources: [EnrollmentEditClicked, EnrollmentChangeStatusClicked],
  sink({ store, event }) {
    store.selectedEnrollmentId = event.payload.enrollmentId;
  },
});

export const enrollmentSubmitted = createSink<
  PatientsDetailSummaryEnrollmentsState,
  ReturnType<typeof EnrollmentSubmitted>
>({
  sources: [EnrollmentSubmitted],
  async sink({ store, event, broadcast, getStore, select }) {
    const token = getStore('user').accessToken;
    const patientsStore: PatientsState = getStore(patientsStateKey);
    // prettier-ignore
    const patientId = select('router.current-patient-id').value || patientsStore.newlyAddedPatient.id;

    try {
      store.loading = true;
      store.error = null;

      const { data } = await axiosWithAuth(token, broadcast).post(
        `/api/vista/patients/${patientId}/enrollments`,
        event.payload,
      );

      broadcast(PatientEnrollmentSuccessful({ patientId, enrollment: data }));
      DialogInstance.close();
    } catch (error) {
      if (!isAxiosError(error)) {
        if (!isAuthError(error)) {
          console.log(error);
        }
        return;
      }

      handleApiError(store, error);

      broadcast(
        PatientEnrollmentFailed({
          error: store.error,
        }),
      );
    } finally {
      store.loading = false;
    }
  },
});

export const enrollmentUpdateSubmittedSink = createSink<
  PatientsDetailSummaryEnrollmentsState,
  ReturnType<typeof EnrollmentUpdateSubmitted>
>({
  sources: [EnrollmentUpdateSubmitted],
  async sink({ store, event, broadcast, getStore, select }) {
    const userStore: UserState = getStore(userStateKey);
    const patientId = select('router.current-patient-id').value;

    try {
      store.loading = true;
      store.error = null;

      await axiosWithAuth(userStore.accessToken, broadcast).patch(
        `/api/vista/patients/${patientId}/enrollments/${event.payload.authoringId}`,
        event.payload,
      );

      broadcast(EnrollmentUpdateSucceeded());
      DialogInstance.close();
    } catch (error) {
      if (!isAxiosError(error)) {
        if (!isAuthError(error)) {
          console.log(error);
        }
        return;
      }

      handleApiError(store, error);

      broadcast(EnrollmentUpdateFailed());
    } finally {
      store.loading = false;
    }
  },
});

export const enrollmentUnenrollRequestedSink = createSink<
  PatientsDetailSummaryEnrollmentsState,
  ReturnType<typeof UnenrollRequested>
>({
  sources: [UnenrollRequested],
  async sink({ store, event, broadcast, getStore, select }) {
    const userStore: UserState = getStore(userStateKey);
    const patientId = select('router.current-patient-id').value;

    try {
      store.loading = true;
      store.error = null;

      await axiosWithAuth(userStore.accessToken, broadcast).post(
        `/api/vista/patients/${patientId}/enrollments/${event.payload.authoringId}/status`,
        {
          authoringId: event.payload.authoringId,
          status: 'un-enroll',
        },
      );

      broadcast(EnrollmentChangeStatusSucceeded());
      DialogInstance.close();
    } catch (error) {
      if (!isAxiosError(error)) {
        if (!isAuthError(error)) {
          console.log(error);
        }
        return;
      }

      handleApiError(store, error);

      broadcast(EnrollmentChangeStatusFailed());
    } finally {
      store.loading = false;
    }
  },
});

export const enrollmentClearErrorSink = createSink<
  PatientsDetailSummaryEnrollmentsState,
  ReturnType<
    | typeof EnrollmentCreateDialogBackClicked
    | typeof EnrollmentChangeStatusCancelClicked
    | typeof EnrollmentDetailsDialogCancelClicked
  >
>({
  sources: [
    EnrollmentCreateDialogBackClicked,
    EnrollmentDetailsDialogCancelClicked,
    EnrollmentChangeStatusCancelClicked,
  ],
  sink({ store }) {
    store.error = null;
    DialogInstance.close();
  },
});

export const clearPatientsDetailSummaryEnrollmentsState = createSink<
  PatientsDetailSummaryEnrollmentsState,
  ReturnType<typeof PatientDetailUnmounted | typeof OrganizationChanged>
>({
  sources: [PatientDetailUnmounted, OrganizationChanged],
  sink({ store }) {
    store.loading = patientDetailSummaryEnrollmentsInitialState.loading;
    store.error = patientDetailSummaryEnrollmentsInitialState.error;
    store.enrollments = patientDetailSummaryEnrollmentsInitialState.enrollments;
    store.surveys = patientDetailSummaryEnrollmentsInitialState.surveys;
    store.selectedSurveyId =
      patientDetailSummaryEnrollmentsInitialState.selectedSurveyId;
    store.selectedEnrollmentId =
      patientDetailSummaryEnrollmentsInitialState.selectedEnrollmentId;
  },
});
