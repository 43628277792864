/* eslint-disable @typescript-eslint/camelcase */
import { tableMessages } from '@/shared';
import { axiosWithAuth } from '@/shared/axios-with-auth';
import { sortColMap } from '@/shared/sort-col-map';
import { createSink } from '@conversa/sink';
import { PatientDetailFetched } from '../../+state/events';
import { PatientDetailsActivitiesState } from '../../models/patient-details-activities-state.interface';
import {
  PatientDetailsActivitiesTablePageUpdated,
  PatientDetailsActivitiesTableSorted,
  PatientDetailsActivitiesFetched,
} from './events';
import { PatientsState } from '@/patients/models';
import { patientsStateKey } from '@/patients/+state/store';

export const patientDetailsActivitiesRequested = createSink<
  PatientDetailsActivitiesState,
  ReturnType<typeof PatientDetailsActivitiesFetched>
>({
  sources: [PatientDetailsActivitiesFetched, PatientDetailFetched],
  async sink({ store, broadcast, getStore, select }) {
    const router = getStore('router');
    const token = getStore('user').accessToken;
    const patientsStore: PatientsState = getStore(patientsStateKey);

    const patientId =
      select('router.current-patient-id').value ||
      patientsStore.newlyAddedPatient.id;

    const clinicPatientCode = getStore('patients-detail').data
      ?.clinic_patient_code;

    const routename = router.route?.name;

    if (!clinicPatientCode || routename !== 'patientActivity') return;

    try {
      store.loading = true;

      const { data } = await axiosWithAuth(token, broadcast).get(
        `/api/vista/patients/${patientId}/activities`,
        {
          params: {
            with_count: true,
            page: store.page,
            sort_col: sortColMap.get(store.sortCol),
            sort_dir: store.sortDir,
          },
        },
      );

      if (!data.data?.length) {
        store.tableMessage = tableMessages.noResults;
      }

      store.data = data.data;
      store.count = data.count;
    } catch (error) {
      store.data = [];
      store.tableMessage = tableMessages.serverError;
    } finally {
      store.loading = false;
    }
  },
});

export const patientDetailsActivitiesTableSorted = createSink<
  PatientDetailsActivitiesState,
  ReturnType<typeof PatientDetailsActivitiesTableSorted>
>({
  sources: [PatientDetailsActivitiesTableSorted],
  sink({ store, event, broadcast }) {
    store.sortCol = event.payload.col;
    store.sortDir = event.payload.dir;
    store.page = 1;
    broadcast(PatientDetailsActivitiesFetched());
  },
});

export const patientDetailsActivitiesPageUpdated = createSink<
  PatientDetailsActivitiesState,
  ReturnType<typeof PatientDetailsActivitiesTablePageUpdated>
>({
  sources: [PatientDetailsActivitiesTablePageUpdated],
  sink({ store, event, broadcast }) {
    store.page = event.payload.page;
    broadcast(PatientDetailsActivitiesFetched());
  },
});
