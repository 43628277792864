import { action, payload } from 'ts-action';

/**
 * Used in src/patients/+state/sinks.ts
 */
export const PatientStatusDialogDismissed = action(
  '[ component | Patient Status Dialog ] Dismissed',
);

export const PatientStatusChangeRequested = action(
  '[ component | Patient Status Dialog ] Status Change Requested',
  payload<{ status: 'in' | 'out' }>(),
);

export const PatientStatusChanged = action(
  '[ component | Patient Status Dialog ] Status Changed',
);

export const PatientsStatusChangeFailed = action(
  '[ api | vista/patients/:id/status ] Patient Status Change Failed',
);
