import { programProjector } from './projections-map';
import { VuetifySelectOption } from '@/shared/models/select-options.interface';
import { Program } from '../models';

export const programs = programProjector<Program[]>(
  'programs.data',
  ({ store }) => store.programs,
);

export const loadingPrograms = programProjector<boolean>(
  'programs.loading',
  ({ store }) => store.loading,
);

export const programsForSelectAll = programProjector<VuetifySelectOption[]>(
  'programs.select-options.all',
  ({ store }) =>
    (store.programs || []).map(({ id: value, name: content }) => ({
      value,
      text: content,
    })),
);

export const programsForSelectClinician = programProjector<
  VuetifySelectOption[]
>('programs.select-options.clinician', ({ store }) =>
  (store.programs || [])
    .filter(program => program.show_in_clinician_dashboard)
    .map(({ id: value, name: content }) => ({
      value,
      text: content,
    })),
);

export const selectedProgram = programProjector<Program>(
  'programs.selected',
  ({ store }) =>
    store.programs.find(program => program.id === store.selectedProgramId),
);
