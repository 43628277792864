import { createSink } from '@conversa/sink';
import { UserAccessTokenInvalid } from '@/user';
import { removeLastRoute, setLastRoute } from '@/shared';

import { RouterState } from './../models/router-state.interface';
import { RouteChanged } from './events';

export const routeChanged = createSink<
  RouterState,
  ReturnType<typeof RouteChanged>
>({
  sources: [RouteChanged],
  sink({ store, event }) {
    store.route = event.payload.route;
    setLastRoute(event.payload.route.path);
  },
});

export const removePersistentRoute = createSink<
  RouterState,
  ReturnType<typeof RouteChanged>
>({
  sources: [UserAccessTokenInvalid],
  sink() {
    removeLastRoute();
  },
});
