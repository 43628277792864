import { Demographics } from '@conversa/bedazzled/src/models/demographics.interface';
import { capabilitiesProjector } from '@/capabilities/+state/projections-map';
import { LanguagesCapabilities } from '@conversa/bedazzled/src/models/languages-capabilities.interface';
import { VuetifySelectOption } from '@conversa/bedazzled/src/models/select-options.interface';

export const loadingCapabilities = capabilitiesProjector<boolean>(
  'capabilities.loading',
  ({ store }) => store.loading,
);

export const caregiverFeatCapabilities = capabilitiesProjector<boolean>(
  'capabilities.features.caregiver',
  ({ store }) => store.capabilities.features.caregiver,
);

export const feedbackFeatCapabilities = capabilitiesProjector<boolean>(
  'capabilities.features.feedback',
  ({ store }) => store.capabilities.features.feedback,
);

export const patientEditFeatCapabilities = capabilitiesProjector<boolean>(
  'capabilities.features.patientEdit',
  ({ store }) => store.capabilities.features.patientEdit,
);

export const chartingFeatCapabilities = capabilitiesProjector<boolean>(
  'capabilities.features.charting',
  ({ store }) => store.capabilities.features.charting,
);

export const reportsFeatCapabilities = capabilitiesProjector<boolean>(
  'capabilities.features.reports',
  ({ store }) => store.capabilities.features.reports,
);

export const smsFeatCapabilities = capabilitiesProjector<boolean>(
  'capabilities.features.sms',
  ({ store }) => store.capabilities.features.sms,
);

export const demographicCapabilities = capabilitiesProjector<Demographics>(
  'capabilities.demographics',
  ({ store }) => store.capabilities.demographics,
);

export const languageCapabilities = capabilitiesProjector<
  LanguagesCapabilities
>('capabilities.languages', ({ store }) => store.capabilities.languages);

export const enabledLanguagesSelectOptions = capabilitiesProjector<
  VuetifySelectOption[]
>('capabilities.enabledLanguages.selectOptions', ({ store }) =>
  store.capabilities.enabledLanguages.map(([languageName, languageCode]) => ({
    text: languageName,
    value: languageCode,
  })),
);

export const hasMultipleEnabledLanguages = capabilitiesProjector<boolean>(
  'capabilities.enabledLanguages.hasMultiple',
  ({ select }) =>
    select('capabilities.enabledLanguages.selectOptions').value.length >= 2,
);

export const i18nDateCapabilities = capabilitiesProjector<string>(
  'capabilities.international.datetimeFormat',
  ({ store }) => store.capabilities.international.datetimeFormat,
);
