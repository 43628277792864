import { createModule } from '@conversa/sink';
import * as models from '../models';
import * as projections from './projections';
import * as sinks from './sinks';
import { patientChartingStore as store } from './store';

export const patientsDetailSummaryChartingModule = createModule<
  models.PatientChartState
>({
  projections,
  sinks,
  store,
});
