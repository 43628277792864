import { useIsDesktopListener } from '@/shared';
import { computed } from '@vue/composition-api';
import sink from '@/sink';
import { CapabilitiesProjectionsMap } from '@/capabilities';

export function getNavOptions() {
  const isDesktop = useIsDesktopListener();

  const navOptionsSrc = [
    {
      to: '/patients',
      content: 'Patients',
      icon: 'person_search',
    },
    {
      to: '/chats',
      content: 'Chats',
      icon: 'chat',
    },
    {
      to: '/responses',
      content: 'Responses',
      icon: 'question_answer',
    },
    {
      to: '/patient-engagement',
      content: 'Patient Engagement',
      icon: 'phone_disabled',
    },
    {
      to: '/activity',
      content: 'Activity',
      icon: 'list_alt',
    },
    {
      to: '/reporting',
      content: 'Reporting',
      icon: 'pie_chart',
    },
    {
      to: '/feedback',
      content: 'Feedback',
      icon: 'feedback',
    },
  ];

  const featureMap: { [route: string]: keyof CapabilitiesProjectionsMap } = {
    '/feedback': 'capabilities.features.feedback',
    '/reporting': 'capabilities.features.reports',
  };

  return computed(() => {
    const routesToFilter = [];

    if (!isDesktop.value) {
      routesToFilter.push('/');
    }

    for (const [route, sinkSelector] of Object.entries(featureMap)) {
      if (!sink.select(sinkSelector).value) {
        routesToFilter.push(route);
      }
    }

    return navOptionsSrc.filter(ro => !routesToFilter.includes(ro.to));
  });
}
