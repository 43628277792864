import { PatientDetailsActivitiesSortCol, SortDir } from '@/shared';
import { action, payload } from 'ts-action';

export const PatientDetailsActivitiesFetched = action(
  '[ component | PatientDetailsActivyShell ] Patient Detail Activities Requested',
);

export const PatientDetailsActivitiesTableSorted = action(
  '[ component | PatientDetailsActivyShell ] Table Sorted',
  payload<{
    col: PatientDetailsActivitiesSortCol;
    dir: SortDir;
  }>(),
);

export const PatientDetailsActivitiesTablePageUpdated = action(
  '[ component | PatientDetailsActivyShell ] Page Updated',
  payload<{ page: number }>(),
);
