import { createModule } from '@conversa/sink';
import { ResponsesState } from '../models';
import * as projections from './projections';
import * as sinks from './sinks';
import { responsesStore as store } from './store';

export * from './projections-map';
export * from './events';
export const responsesModule = createModule<ResponsesState>({
  projections,
  sinks,
  store,
});
