import { TableMessage } from '../../shared/empty-table-messages';
import { PatientEngagementsSortCol, SortDir, ErrorState } from '@/shared';
import { createProjector } from '@conversa/sink';
import {
  PatientEngagementsFilters,
  PatientEngagementsState,
  PatientEngagementTable,
  PatientEngagementsSearchFiltersPayload,
} from '../models';

export interface PatientEngagementProjectionsMap {
  'patientEngagement.filters': PatientEngagementsFilters;
  'patientEngagement.table': PatientEngagementTable;
  'patientEngagement.search-payload': PatientEngagementsSearchFiltersPayload;
  'patientEngagement.error': ErrorState;
  'patientEngagement.sort-col': PatientEngagementsSortCol;
  'patientEngagement.sort-dir': SortDir;
  'patientEngagement.filters-dirty': boolean;
  'patientEngagement.page-number': number;
  'patientEngagement.count': number;
  'patientEngagement.loading': boolean;
  'patientEngagement.tableMessage': TableMessage;
}

export const patientEngagementProjector = createProjector<
  PatientEngagementProjectionsMap,
  PatientEngagementsState
>();
