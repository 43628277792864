import { TableMessage } from './../../shared/empty-table-messages';
import { EscalationsSortCol, SortDir } from '@/shared/models';
import { createProjector } from '@conversa/sink';
import {
  EscalationsFilters,
  EscalationsFiltersPayload,
  EscalationsState,
  EscalationsTable,
} from '../models';
import { EscalationsTableHeaders } from '../models/escalation-headers.interface';

export interface EscalationsProjectionsMap {
  'escalations.filters': EscalationsFilters;
  'escalations.firstName': string;
  'escalations.loading': boolean;
  'escalations.checkup.response.loading': number;
  'escalations.table': EscalationsTable;
  'escalations.search-payload': EscalationsFiltersPayload;
  'escalations.sort-col': EscalationsSortCol;
  'escalations.sort-dir': SortDir;
  'escalations.filters-dirty': boolean;
  'escalations.page-number': number;
  'escalations.count': number;
  'escalations.table-expanded-rows': number[];
  'escalations.tableMessage': TableMessage;
  'escalations.headers': EscalationsTableHeaders;
}

export const escalationsProjector = createProjector<
  EscalationsProjectionsMap,
  EscalationsState
>();
