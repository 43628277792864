import { OrganizationChanged } from '@/organizations/+state/events';
import { PatientDetailUnmounted } from '@/patients/detail/+state/events';
import { isAxiosError } from '@/shared';
import { handleApiError } from '@/shared/api/set-store-error';
import { axiosWithAuth, isAuthError } from '@/shared/axios-with-auth';
import { DialogInstance } from '@conversa/bedazzled/src/dialog';
import { createSink } from '@conversa/sink';
import { PatientsDetailSummaryCaregiverState } from '../models';
import {
  CaregiverCreated,
  CaregiverCreateFailed,
  CaregiverCreateSubmitted,
  CaregiverInformationMounted,
  CaregiverStatusChangeRequested,
  CaregiverUpdated,
  CaregiverUpdateFailed,
  CaregiverUpdateSubmitted,
  CaregiverDialogDismissed,
} from './events';
import { patientDetailSummaryCaregiverInitialState } from './store';

export const caregiversRequested = createSink<
  PatientsDetailSummaryCaregiverState,
  ReturnType<typeof CaregiverInformationMounted>
>({
  sources: [CaregiverInformationMounted, CaregiverCreated],
  async sink({ store, select, broadcast, getStore }) {
    const token = getStore('user').accessToken;
    const patientId = select('router.current-patient-id').value;

    try {
      store.loading = true;
      store.error = null;

      const { data } = await axiosWithAuth(token, broadcast).get(
        `/api/vista/patients/${patientId}/caregivers`,
      );

      store.data = data.data;
    } catch (error) {
      if (!isAxiosError(error)) {
        if (!isAuthError(error)) {
          console.log(error);
        }
        return;
      }

      handleApiError(store, error);
    } finally {
      store.loading = false;
    }
  },
});

export const caregiverCreateSubmittedSink = createSink<
  PatientsDetailSummaryCaregiverState,
  ReturnType<typeof CaregiverCreateSubmitted>
>({
  sources: [CaregiverCreateSubmitted],
  async sink({ store, getStore, select, broadcast, event }) {
    const token = getStore('user').accessToken;
    const patientId = select('router.current-patient-id').value;

    try {
      store.loading = true;
      store.error = null;

      await axiosWithAuth(token, broadcast).post(
        `/api/vista/patients/${patientId}/caregivers`,
        event.payload,
      );

      broadcast(CaregiverCreated());
      DialogInstance.close();
    } catch (error) {
      if (!isAxiosError(error)) {
        if (!isAuthError(error)) {
          console.log(error);
        }
        return;
      }

      handleApiError(store, error);

      broadcast(CaregiverCreateFailed());
    } finally {
      store.loading = false;
    }
  },
});

export const caregiverEditSubmittedSink = createSink<
  PatientsDetailSummaryCaregiverState,
  ReturnType<
    typeof CaregiverUpdateSubmitted | typeof CaregiverStatusChangeRequested
  >
>({
  sources: [CaregiverUpdateSubmitted, CaregiverStatusChangeRequested],
  async sink({ store, getStore, select, broadcast, event }) {
    const token = getStore('user').accessToken;
    const patientId = select('router.current-patient-id').value;

    try {
      store.loading = true;
      store.error = null;

      const { data } = await axiosWithAuth(token, broadcast).patch(
        `/api/vista/patients/${patientId}/caregivers`,
        event.payload,
      );

      store.data = [data];

      broadcast(CaregiverUpdated());

      DialogInstance.close();
    } catch (error) {
      if (!isAxiosError(error)) {
        if (!isAuthError(error)) {
          console.log(error);
        }
        return;
      }

      handleApiError(store, error);

      broadcast(CaregiverUpdateFailed());
    } finally {
      store.loading = false;
    }
  },
});

export const clearPatientsDetailSummaryCaregiverState = createSink<
  PatientsDetailSummaryCaregiverState,
  ReturnType<typeof PatientDetailUnmounted | typeof OrganizationChanged>
>({
  sources: [PatientDetailUnmounted, OrganizationChanged],
  sink({ store }) {
    store.loading = patientDetailSummaryCaregiverInitialState.loading;
    store.error = patientDetailSummaryCaregiverInitialState.error;
    store.data = patientDetailSummaryCaregiverInitialState.data;
  },
});

export const caregiverDialogDismissed = createSink<
  PatientsDetailSummaryCaregiverState,
  ReturnType<typeof CaregiverDialogDismissed>
>({
  sources: [CaregiverDialogDismissed],
  sink({ store }) {
    store.error = patientDetailSummaryCaregiverInitialState.error;
  },
});
