import { TableMessage } from './../../shared/empty-table-messages';
import { EscalationsSortCol } from './../../shared/models/sort-col.type';
import { SortDir } from './../../shared/models/sort-dir.type';
import { buildFilterPayload, ColorStatus } from '@/shared';
import { dateFormatter } from '../../../../../shared/date-formatter';
import {
  EscalationsFilters,
  EscalationsFiltersPayload,
  EscalationsTable,
  EscalationsTableRow,
} from '../models';
import { escalationsProjector } from './projections-map';
import { reactive } from '@vue/composition-api';
import { capabilitiesStateKey } from '@/capabilities/+state/store';
import { CapabilitiesState } from '@conversa/bedazzled/src/models/capabilities-state.interface';
import { EscalationsTableHeaders } from '../models/escalation-headers.interface';

export const escalationsFilters = escalationsProjector<EscalationsFilters>(
  'escalations.filters',
  ({ store }) => store.filters,
);

export const escalationTableHeaders = escalationsProjector<
  EscalationsTableHeaders
>('escalations.headers', ({ store }) => {
  const enableSort = !!store.filters.programIds.length;
  return [
    { value: 'chatDate', text: 'Chat Date', sortable: enableSort },
    { value: 'patientName', text: 'Patient', sortable: enableSort },
    { value: 'programModule', text: 'Program Module', sortable: false },
    { value: 'providerName', text: 'Provider', sortable: false },
    { value: 'chatStatus', text: 'Chat Status', sortable: enableSort },
    { value: 'data-table-expand', text: '', sortable: false },
  ];
});

export const escalationsTable = escalationsProjector<EscalationsTable>(
  'escalations.table',
  ({ store, getStore }) => {
    const cStore = getStore(capabilitiesStateKey) as CapabilitiesState;
    return store.data.map(d =>
      reactive<EscalationsTableRow>({
        chatDate: dateFormatter(
          d.chat_date,
          cStore.capabilities.international.datetimeFormat,
          'dateTime',
        ),
        patientName: `${d.patient_last_name}, ${d.patient_first_name}`,
        firstName: d.patient_first_name,
        lastName: d.patient_last_name,
        patientId: d.id,
        clinicId: d.clinic_patient_code,
        programModule: d.chat_name,
        providerName: `${d.provider_last_name}, ${d.provider_first_name}`,
        chatStatus: d.chat_status as ColorStatus,
        checkupId: +d.checkup_id,
        responses: d.responses.map(response => {
          const isDate = /^\d+\/\d+\/\d+$/g.test(response.response_text);

          return {
            question: response.question_text,
            response: isDate
              ? dateFormatter(
                  response.response_text,
                  cStore.capabilities.international.datetimeFormat,
                  'date',
                )
              : response.response_text,
            responseStatus: response.response_status,
            id: +response.id,
            clinicId: response.clinic_patient_code,
            patientId: +response.patient_id,
          };
        }),
        responsesLoading: d.responsesLoading,
      }),
    );
  },
);

export const escalationsSearchPayload = escalationsProjector<
  EscalationsFiltersPayload
>('escalations.search-payload', ({ store, select }) => {
  const map = new Map<keyof EscalationsFilters, string>([
    ['chatStatus', 'chat_status'],
    ['enrollmentDateFrom', 'chat_date_from'],
    ['enrollmentDateTo', 'chat_date_to'],
    ['patientStatus', 'patient_status'],
    ['programIds', 'program_ids'],
    ['providerIds', 'provider_ids'],
  ]);

  const payload: EscalationsFiltersPayload = {
    // eslint-disable-next-line @typescript-eslint/camelcase
    org_id: select('organizations.selected').value.id,
    ...buildFilterPayload<EscalationsFiltersPayload>(
      store,
      map,
      'escalationsSearchFilters',
      {
        enrollmentDateTo: 'EndOfDay',
      },
    ),
  };

  return payload;
});

export const tableMessage = escalationsProjector<TableMessage>(
  'escalations.tableMessage',
  ({ store }) => store.tableMessage,
);
export const count = escalationsProjector<number>(
  'escalations.count',
  ({ store }) => store.count,
);
export const loading = escalationsProjector<boolean>(
  'escalations.loading',
  ({ store }) => store.loading,
);
export const sortCol = escalationsProjector<EscalationsSortCol>(
  'escalations.sort-col',
  ({ store }) => store.sortCol,
);
export const sortDir = escalationsProjector<SortDir>(
  'escalations.sort-dir',
  ({ store }) => store.sortDir,
);
export const filtersDirty = escalationsProjector<boolean>(
  'escalations.filters-dirty',
  ({ store }) => store.filtersDirty,
);
export const pageNumber = escalationsProjector<number>(
  'escalations.page-number',
  ({ store }) => store.page,
);
export const exRows = escalationsProjector<EscalationsTableRow[]>(
  'escalations.table-expanded-rows',
  ({ store }) => store.exRows,
);
