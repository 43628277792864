import { CaregiverCreateUpdatePayload } from '../models';
import { action, payload } from 'ts-action';

/**
 * Used in @/user/+state/sinks.clearErrorState
 */
export const CaregiverDialogDismissed = action(
  '[ component | Caregiver Dialog ] Dismissed',
);

export const CaregiverCreateSubmitted = action(
  '[ component | Caregiver Create Dialog ] Create Caregiver Submitted',
  payload<Omit<CaregiverCreateUpdatePayload, 'patientId'>>(),
);

export const CaregiverUpdateSubmitted = action(
  '[ component | Caregiver Create Dialog ] Caregiver Update Submitted',
  payload<Omit<CaregiverCreateUpdatePayload, 'patientId'>>(),
);

export const CaregiverCreated = action(
  '[ api | post /patients/:id/caregiver ] Caregiver Created',
);

export const CaregiverCreateFailed = action(
  '[ api | post /patients/:id/caregiver ] Caregiver Create Failed',
);

export const CaregiverUpdated = action(
  '[ api | patch /patients/:id/caregiver ] Caregiver Updated',
);

export const CaregiverUpdateFailed = action(
  '[ api | patch /patients/:id/caregiver ] Caregiver Update Failed',
);

export const CaregiverStatusChangeRequested = action(
  '[ api | patch /patients/:id/caregiver ] Caregiver Status Update Requested',
  payload<{ active: boolean }>(),
);

export const CaregiverInformationMounted = action(
  '[ component | patient detail caregivers] Mounted',
);
