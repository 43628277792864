export const getObjectData = <T>(object: T) => {
  return Object.keys(object).reduce((acc, key) => {
    const filter = object[key];
    if (
      (Array.isArray(filter) && filter.length) ||
      (!Array.isArray(filter) && filter)
    )
      acc[key] = object[key];

    return acc;
  }, {}) as Partial<T>;
};
