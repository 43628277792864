import { Provider, ProvidersState } from './../models';

import {
  OrganizationChanged,
  OrganizationsLoaded,
} from '@/organizations/+state/events';
import { createSink } from '@conversa/sink';
import { axiosWithAuth } from '@/shared/axios-with-auth';
import { UserAccessTokenInvalid } from '@/user/+state/events';
import { providersInitialState } from './store';

export const loadProviders = createSink<
  ProvidersState,
  ReturnType<typeof OrganizationsLoaded | typeof OrganizationChanged>
>({
  sources: [OrganizationsLoaded, OrganizationChanged],
  async sink({ store, getStore, broadcast }) {
    const token = getStore('user').accessToken;
    const selectedOrgId = getStore('organizations-state').selectedOrgId;
    store.loadingProviders = true;

    try {
      const providers = (
        await axiosWithAuth(token, broadcast).post<{ data: Provider[] }>(
          '/api/vista/providers/search',
          // eslint-disable-next-line
          { filters: { org_id: selectedOrgId } },
        )
      ).data.data;

      store.providers = providers;
      store.loadingProviders = false;
    } catch (error) {
      store.loadingProviders = false;
    }
  },
});

export const clearProvidersState = createSink<
  ProvidersState,
  ReturnType<typeof UserAccessTokenInvalid>
>({
  sources: [UserAccessTokenInvalid],
  sink({ store }) {
    store.providers = providersInitialState.providers;
    store.loadingProviders = providersInitialState.loadingProviders;
  },
});
