

















import DialogTitle from '@/dialog/DialogTitle.vue';
import { defineComponent } from '@vue/composition-api';
import { ErrorState } from '../models/error-state.interface';

export default defineComponent<{ error: ErrorState }>({
  props: ['error'],
  components: { DialogTitle },
  setup(_, ctx) {
    return {
      dismissed: () => ctx.emit('dismissed'),
    };
  },
});
