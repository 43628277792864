import { SortDir } from '@/shared';
import { action, payload } from 'ts-action';
import { PatientDetailsCompletedChatRow } from '../models/completed-chat.interface';
import { PatientDetailsCompletedChatsSortedCol } from '../models/sorted-col.interface';

export const FetchPatientCompletedChats = action(
  '[ api | vista/escalations/search ] Fetch Patient Completed Chats',
);

export const PatientCompletedChatsTableSorted = action(
  '[ component | Patient Completed Chats Shell ] Table Sorted',
  payload<{ col: PatientDetailsCompletedChatsSortedCol; dir: SortDir }>(),
);

export const PatientCompletedChatsPageUpdated = action(
  '[ component | Patient Completed Chats Shell ] Page Updated',
  payload<{ page: number }>(),
);

export const PatientCompletedChatsRowExpanded = action(
  '[ component | Patient Completed Chats Shell ] Row Expanded',
  payload<{ item: PatientDetailsCompletedChatRow; isExpanded: boolean }>(),
);

export const PatientCompletedChatsResponsesRequested = action(
  '[ component | Patient Completed Chats Shell ] Responses Requested',
  payload<{ id: number }>(),
);
