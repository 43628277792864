import { createStoreV2 } from '@conversa/sink';
import { PatientsDetailSummaryCaregiverState } from '../models';

export const patientsDetailSummaryCaregiverStateKey =
  'patients-detail-summary-caregiver-state';

export const patientDetailSummaryCaregiverInitialState: PatientsDetailSummaryCaregiverState = {
  loading: false,
  error: null,
  data: [],
};

export const patientDetailSummaryCaregiverStore = createStoreV2(
  patientsDetailSummaryCaregiverStateKey,
  patientDetailSummaryCaregiverInitialState,
);
