import { content } from './content';
import { PatientStatus, ChatStatus } from './models';
import { MidnightTimeOfDay } from './models/time-of-day.type';

export function isPositiveInteger(unknownNumber: unknown): boolean {
  if (!unknownNumber) {
    return false;
  }

  const potentialNumber = Number(unknownNumber);

  return (
    !isNaN(potentialNumber) &&
    Number.isInteger(potentialNumber) &&
    potentialNumber > 0
  );
}

export function humanReadableActiveCaregiver(hasActive: 't' | 'f') {
  switch (hasActive) {
    case 't':
      return content.en.activeCaregiver.t;

    case 'f':
      return content.en.activeCaregiver.f;

    default:
      return hasActive;
  }
}

export function humanReadablePatientStatus(status: PatientStatus) {
  switch (status) {
    case 'opted_in':
      return content.en.patientStatus.in;

    case 'opted_out':
      return content.en.patientStatus.out;

    default:
      return status;
  }
}

export function humanReadableChatStatus(status: ChatStatus) {
  return content.en.chatStatus[status] || status;
}

export function getMidnightIsoDate(
  date: string,
  from: MidnightTimeOfDay = 'StartOfDay',
) {
  const midnight = new Date(date);
  from === 'EndOfDay'
    ? midnight.setHours(23, 59, 59, 999)
    : midnight.setHours(0, 0, 0, 0);

  return midnight.toISOString();
}

export function buildFilterPayload<T>(
  /* eslint-disable */
  store: { filters: { [k: string]: any } },
  paramMap: Map<string, string>,
  /* eslint-enable */
  context: string,
  isoDateTimeOfDay?: { [key: string]: MidnightTimeOfDay },
) {
  return Object.keys(store.filters).reduce((filters, key) => {
    const val = store.filters[key];
    if ((Array.isArray(val) && val.length) || (!Array.isArray(val) && val)) {
      const filterKey = paramMap.get(key);

      if (filterKey) {
        filters[filterKey] = isoDateTimeOfDay?.[key]
          ? getMidnightIsoDate(val, isoDateTimeOfDay[key])
          : val;
      } else {
        console.warn(`${context} attempted to set an unknown filter`, {
          filterKey,
        });
      }
    }

    return filters;
  }, {}) as T;
}

/* eslint-disable @typescript-eslint/camelcase */
export function mapPayload<T>(
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  srcParams: Record<string, any>,
  paramMap: Map<string, string>,
  /* eslint-enable */
  context: string,
) {
  return Object.keys(srcParams).reduce((targetParams, key) => {
    const val = srcParams[key];
    if ((Array.isArray(val) && val.length) || (!Array.isArray(val) && val)) {
      const paramKey = paramMap.get(key);

      if (paramKey) {
        targetParams[paramKey] = val;
      } else {
        console.warn(`${context} attempted to map an unknown parameter`, {
          paramKey,
        });
      }
    }

    return targetParams;
  }, {}) as T;
  /* eslint-enable @typescript-eslint/camelcase */
}

export function handleErrorMessage({ store }) {
  const errorMessage = [];

  const message = store.error?.message;

  const detailErrorsIsArray = Array.isArray(store.error?.details?.errors);
  const details =
    (detailErrorsIsArray && store.error.details.errors[0]?.message) ||
    store.error?.details;

  const errors =
    detailErrorsIsArray && store.error.details.errors[0]?.value?.toString();

  if (message) errorMessage.push(message);
  if (details) errorMessage.push(`- ${details}`);
  if (errors) errorMessage.push(`: ${errors}`);

  return errorMessage.join(' ');
}

export const setLastRoute = (path: string) => {
  sessionStorage.setItem('lastRoute', path);
};

export const getLastRoute = () => {
  return sessionStorage.getItem('lastRoute');
};

export const removeLastRoute = () => {
  sessionStorage.removeItem('lastRoute');
};

export const setOrgId = (id: string) => {
  sessionStorage.setItem('selectedOrgId', id);
};

export const getOrgId = () => {
  return sessionStorage.getItem('selectedOrgId');
};

export const removeOrgId = () => {
  sessionStorage.removeItem('selectedOrgId');
};
