// These are the only HTTP status codes that we care to share messages from
import { defaultAlertMessage } from '@/shared';

// [dw] 2021-06-25 - this is commented out until we address backend sending validations
// const httpStatusWithMessage = [400, 422];
const httpStatusWithMessage = [];

export function handleApiError(store, error) {
  if (httpStatusWithMessage.includes(error.response.status)) {
    if (!error.response.data.details) {
      store.error = {
        code: error.response.status,
        message: error.response.statusText,
        details: {
          errors: [{ message: error.response.statusText, field: null }],
        },
      };
    } else {
      store.error = error.response.data;
    }
  } else {
    store.error = {
      code: error.response.status,
      message: defaultAlertMessage,
    };
  }
}
