import { tableMessages } from './../../shared/empty-table-messages';
import {
  OrganizationChanged,
  OrganizationsLoaded,
} from '@/organizations/+state/events';
import {
  filterInputUpdate,
  getObjectData,
  isAxiosError,
  loadFilters,
  Payload,
  persistFilters,
  restoreFilters,
} from '@/shared';
import { axiosWithAuth } from '@/shared/axios-with-auth';
import { sortColMap } from '@/shared/sort-col-map';
import { VistaProjections } from '@/sink';
import { UserAccessTokenInvalid } from '@/user/+state/events';
import { createSink } from '@conversa/sink';
import {
  EscalationsFilters,
  EscalationsFiltersPayload,
  EscalationsState,
} from '../models';
import {
  EscalationResponsesRequested,
  EscalationsFilterChanged,
  EscalationsFiltersCleared,
  EscalationsFilterSearched,
  EscalationsPageUpdated,
  EscalationsSearchFailed,
  EscalationsTableSorted,
  EscalationResponsesSearchFailed,
  EscalationsRowExpanded,
  EscalationsFiltersRestored,
} from './events';
import { escalationsInitialState } from './store';

export const expandedRowsSink = createSink<
  EscalationsState,
  ReturnType<typeof EscalationsRowExpanded>
>({
  sources: [EscalationsRowExpanded],
  sink({ store, event }) {
    if (event.payload.isExpanded) {
      store.exRows.push(event.payload.item);
      return;
    }

    const included = store.exRows.findIndex(
      item => item.checkupId === event.payload.item.checkupId,
    );

    if (included >= 0) {
      store.exRows.splice(included, 1);
    }
  },
});

export const escalationsFilterChanged = createSink<
  EscalationsState,
  ReturnType<typeof EscalationsFilterChanged>
>({
  sources: [EscalationsFilterChanged],
  sink: filterInputUpdate,
});

const clearFilters = (store: EscalationsState) => {
  store.filters.chatStatus = escalationsInitialState.filters.chatStatus;
  store.filters.enrollmentDateFrom =
    escalationsInitialState.filters.enrollmentDateFrom;
  store.filters.enrollmentDateTo =
    escalationsInitialState.filters.enrollmentDateTo;
  store.filters.patientStatus = escalationsInitialState.filters.patientStatus;
  store.filters.programIds = escalationsInitialState.filters.programIds;
  store.filters.providerIds = escalationsInitialState.filters.providerIds;
  store.filters.showTestPatients =
    escalationsInitialState.filters.showTestPatients;
};

export const esclationsFiilterCleared = createSink<
  EscalationsState,
  ReturnType<typeof EscalationsFiltersCleared>
>({
  sources: [EscalationsFiltersCleared],
  sink({ store, getStore }) {
    clearFilters(store);
    persistFilters({
      filter: 'responses',
      value: {},
      orgId: getStore('organizations-state').selectedOrgId,
    });
    store.filtersDirty = true;
  },
});

export const escalationsFiltersRestored = createSink<
  EscalationsState,
  ReturnType<typeof EscalationsFiltersRestored | typeof OrganizationsLoaded>
>({
  sources: [EscalationsFiltersRestored, OrganizationsLoaded],
  sink({ store, getStore, event }) {
    const selectedOrgId = getStore('organizations-state').selectedOrgId;

    if (!selectedOrgId) return;

    const storagedFilters = loadFilters<EscalationsFilters>(
      'chats',
      selectedOrgId,
    );

    if (Object.keys(storagedFilters).length)
      restoreFilters({ event, store, filters: storagedFilters });
  },
});

export const escalationsFilterSearched = createSink<
  EscalationsState,
  ReturnType<typeof EscalationsFilterSearched>
>({
  sources: [EscalationsFilterSearched],
  async sink({ store, select, broadcast, getStore, event }) {
    const token = getStore('user').accessToken;

    store.filtersDirty = false;

    if (event.payload.resetPage) {
      store.page = 1;
    }

    persistFilters({
      filter: 'chats',
      value: getObjectData(store.filters),
      orgId: getStore('organizations-state').selectedOrgId,
    });

    const payload: Payload<EscalationsFiltersPayload> = {
      pagination: {
        /* eslint-disable @typescript-eslint/camelcase */
        with_count: true,
        sort_col: sortColMap.get(store.sortCol),
        sort_dir: store.sortDir,
        /* eslint-enable @typescript-eslint/camelcase */
        page: store.page,
      },
      filters: select<keyof VistaProjections>('escalations.search-payload')
        .value,
    };

    try {
      store.loading = true;
      const { data } = await axiosWithAuth(token, broadcast).post(
        '/api/vista/escalations/search',
        payload,
      );

      if (!data.data?.length) {
        store.tableMessage = tableMessages.noResults;
      }

      store.data = data.data.map(d => {
        d.responses = [];
        d.responsesLoading = false;

        return d;
      });

      store.exRows = [];
      store.count = data.count;
      store.loading = false;
    } catch (error) {
      store.loading = false;
      if (!isAxiosError(error)) {
        console.log(error);
        return;
      }

      store.error = error.response.data;

      broadcast(
        EscalationsSearchFailed({
          payload,
          status: error.response.status,
          message: error.response.data,
        }),
      );
    }
  },
});

export const clearEscalationsState = createSink<
  EscalationsState,
  ReturnType<typeof UserAccessTokenInvalid | typeof OrganizationChanged>
>({
  sources: [UserAccessTokenInvalid, OrganizationChanged],
  async sink({ store, broadcast }) {
    store.data = escalationsInitialState.data;
    store.count = escalationsInitialState.count;
    store.error = escalationsInitialState.error;
    store.loading = escalationsInitialState.loading;
    store.page = escalationsInitialState.page;
    store.sortCol = escalationsInitialState.sortCol;
    store.sortDir = escalationsInitialState.sortDir;
    store.filtersDirty = escalationsInitialState.filtersDirty;
    store.tableMessage = escalationsInitialState.tableMessage;
    store.exRows = escalationsInitialState.exRows;
    clearFilters(store);
    broadcast(EscalationsFiltersRestored());
  },
});

export const escalationsTableSortedSink = createSink<
  EscalationsState,
  ReturnType<typeof EscalationsTableSorted>
>({
  sources: [EscalationsTableSorted],
  sink({ store, event, broadcast }) {
    if (!store.filtersDirty) {
      store.exRows = [];
      store.sortCol = event.payload.col;
      store.sortDir = event.payload.dir;
      broadcast(EscalationsFilterSearched({ origin: 'sort' }));
      return;
    }

    const shouldSearch = confirm(
      'Your filters recently changed. Sorting now will perform a new search based on the currently selected filters.\n\nWould you like to continue?',
    );

    if (shouldSearch) {
      store.exRows = [];
      store.sortCol = event.payload.col;
      store.sortDir = event.payload.dir;
      broadcast(EscalationsFilterSearched({ origin: 'sort', resetPage: true }));
    }
  },
});

export const escalationsPageUpdated = createSink<
  EscalationsState,
  ReturnType<typeof EscalationsPageUpdated>
>({
  sources: [EscalationsPageUpdated],
  sink({ store, event, broadcast }) {
    if (!store.filtersDirty) {
      store.exRows = [];
      store.page = event.payload.page;
      broadcast(EscalationsFilterSearched({ origin: 'sort' }));
      return;
    }

    const shouldSearch = confirm(
      'Your filters recently changed. Changing the page now will perform a new search based on the currently selected filters.\n\nWould you like to continue?',
    );

    if (shouldSearch) {
      store.exRows = [];
      store.page = event.payload.page;
      broadcast(EscalationsFilterSearched({ origin: 'sort', resetPage: true }));
    }
  },
});

export const escalationResponsesRequested = createSink<
  EscalationsState,
  ReturnType<typeof EscalationResponsesRequested>
>({
  sources: [EscalationResponsesRequested],
  gate: ({ store, event }) =>
    !store.data.find(d => +d.checkup_id === event.payload.id).responses.length,
  async sink({ store, event, broadcast, getStore }) {
    const token = getStore('user').accessToken;
    const escalationIndex = store.data.findIndex(
      d => +d.checkup_id === event.payload.id,
    );

    try {
      store.data[escalationIndex].responsesLoading = true;

      const { data } = await axiosWithAuth(token, broadcast).get(
        `/api/vista/escalations/${event.payload.id}`,
      );

      store.data[escalationIndex].responses = data;
    } catch (error) {
      if (!isAxiosError(error)) {
        console.log(error);
        return;
      }

      store.error = error.response.data;

      broadcast(
        EscalationResponsesSearchFailed({
          status: error.response.status,
          message: error.response.data,
        }),
      );
    } finally {
      store.data[escalationIndex].responsesLoading = false;
    }
  },
});
