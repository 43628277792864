import { Route } from 'vue-router';
import { routeProjector } from './projections-map';

export const currentRoute = routeProjector<Route>(
  'router.route',
  ({ store }) => store.route,
);

export const currentPatientId = routeProjector<number>(
  'router.current-patient-id',
  ({ store }) => Number(store.route?.params?.patientId),
);

export const currentReportId = routeProjector<string>(
  'router.current-report-id',
  ({ store }) => store.route?.params?.reportId,
);
