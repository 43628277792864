import { createModule } from '@conversa/sink';
import { patientsDetailActivitiesStore as store } from './store';
import { PatientDetailsActivitiesState } from '../../models';
import * as projections from './projections';
import * as sinks from './sinks';

export const patientDetailsActivitiesModule = createModule<
  PatientDetailsActivitiesState
>({
  projections,
  sinks,
  store,
});
