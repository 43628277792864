import { createModule } from '@conversa/sink';
import { PatientDetailsCompletedChatsState } from '../models/store.interface';
import * as projections from './projections';
import * as sinks from './sinks';
import { patientDetailsCompletedChatsStore as store } from './store';

export const patientDetailsCompletedChatsModule = createModule<
  PatientDetailsCompletedChatsState
>({
  projections,
  sinks,
  store,
});
