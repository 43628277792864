import {
  format as formatDate,
  isValid,
  parseISO,
  format,
  parse,
} from 'date-fns';
import { locales, options } from '../../../../shared/date-formatter';

/**
 * Returns a locale's date format as a date-fns friendly string with locale correct order and separators
 *
 * @param {string} setting
 * @returns {string} - i.e. dd.MM.YYYY
 */
export function datefnsMask(setting: string, withTime?: boolean) {
  const locale = locales[setting] || 'en-US';

  const parts = new Intl.DateTimeFormat(
    locale,
    withTime ? options.dateTime : options.date,
  ).formatToParts();

  return parts
    .map(part => {
      if (part.type === 'year') return 'yyyy';
      if (part.type === 'month') return 'MM';
      if (part.type === 'day') return 'dd';
      if (part.type === 'dayPeriod') return 'aaa';
      if (part.type === 'hour') return 'hh';
      if (part.type === 'minute') return 'mm';
      if (part.value === ', ') return ' ';
      return part.value;
    })
    .join('');
}

export const datePartValidation = (dateArray: string[]) => {
  if (!Array.isArray(dateArray) || dateArray.length !== 3) {
    return false;
  }

  const [year, month, day] = dateArray;

  const fourRegex = /^\d{4}$/;
  const twoRegex = /^\d{2}$/;

  if (!fourRegex.test(year)) return 'year must be a 4-digit number';
  if (!twoRegex.test(month)) return 'month must be a 2-digit number';
  if (!twoRegex.test(day)) return 'day must be a 2-digit number';

  return true;
};

/**
 * Extracts the year, month and day from a localized date by getting the part order
 * and length via Intl api from a known localized date (new Date() is implicitly
 * called in formatToParts()). Chose to use position rather than splitting on a separator
 * because some localized dates can have variable separators in the same date string
 * leading to unpredictable edge cases.
 * @param {string} dateString - date we're getting parts from
 * @param {string} setting - org specific locale setting (i.e. 'DD/MM/YYYY HH:MM')
 * @returns {string[]} - Array of date parts in year month day order
 */
export function extractPartsFromDate(dateString: string, setting: string) {
  const locale = locales[setting] || 'en-US';

  const parts = new Intl.DateTimeFormat(locale, options.date).formatToParts();

  try {
    // separators in internationalized dates can be unpredicatable, string position is more reliable.
    const partsWithPosition = parts.reduce((withPositions, part, i) => {
      const [, value] = Array.from(withPositions).pop() ?? [];
      const allowedKeys = ['year', 'month', 'day'];
      const partName = allowedKeys.includes(part.type)
        ? part.type
        : `${part.type}_${i}`;

      withPositions.set(partName, {
        position:
          typeof value === 'undefined' ? 0 : value.position + value.length,
        length: part.value.length,
      });

      return withPositions;
    }, new Map<string, { position: number; length: number }>());

    const [year, month, day] = [
      partsWithPosition.get('year'),
      partsWithPosition.get('month'),
      partsWithPosition.get('day'),
    ];

    const outputParts = [
      dateString.slice(year.position, year.position + year.length),
      dateString.slice(month.position, month.position + month.length),
      dateString.slice(day.position, day.position + day.length),
    ];

    const validation = datePartValidation(outputParts);

    if (typeof validation === 'string') throw new Error(validation);

    return outputParts;
  } catch (error) {
    console.trace('extractPartsFromDate failed', {
      dateString,
      setting,
      error,
    });
  }
}

export const isISODate = dateString => isValid(parseISO(dateString));

export const getISOFormat = dateString => new Date(dateString).toISOString();

export const isEnUSDateString = dateString => {
  const enUSRegex = /^(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])\/\d{4}$/;
  return enUSRegex.test(dateString);
};

/**
 * Formats a given ISO date string into a date-fns friendly string in the supplied format
 * @param {string} isoDateString
 * @param {string} [format = 'MM/dd/yyyy'] format
 * @returns {string}
 */
export const formatISODate = (
  isoDateString: string,
  setting = 'MM/DD/YYYY HH:MM',
  withTime?: boolean,
) => {
  let formatted: string;
  const format = datefnsMask(setting, withTime ?? false);

  if (!format || !isoDateString) return ''; // Allows datepicker to clear to/from fields

  try {
    formatted = formatDate(parseISO(isoDateString), format);
  } catch (error) {
    console.trace('formatISODate failed - using "N/A" as fallback', {
      isoDateString,
      format,
      error,
    });
    formatted = 'N/A';
  }

  return formatted;
};

/**
 * Formats dates stored as DatePicker filters into a date-fns friendly format
 * @param {string} date
 * @param {string} setting
 * @returns {string}
 */
export const filterDateParse = (date: string, setting: string) => {
  try {
    return isISODate(date)
      ? format(new Date(date), 'yyyy-MM-dd')
      : format(
          new Date(
            ...(extractPartsFromDate(date, setting).map((part, i) =>
              i === 1 ? Number(part) - 1 : Number(part),
            ) as []),
          ),
          'yyyy-MM-dd',
        );
  } catch (error) {
    console.trace('filterDateParse failed', {
      date,
      setting,
      error,
    });
  }
};

const escapeRegExp = string => {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
};

export const getFullISODate = (isoDateString: string) => {
  try {
    return parseISO(isoDateString).toISOString();
  } catch (error) {
    console.trace('getFullISODate - could not get iso date with timestamp', {
      isoDateString,
      error,
    });

    return isoDateString;
  }
};

// Validation
/**
 * Checks localized date string for format validity
 * @param {string} date
 * @param {string} setting
 * @returns {boolean}
 */
export const isDateFormatValid = (date: string, setting: string) => {
  const locale = locales[setting] || 'en-US';

  const parts = new Intl.DateTimeFormat(locale, options.date).formatToParts();

  const expression = parts.reduce((exp, part) => {
    const dateNumerics = ['year', 'month', 'day'];

    if (dateNumerics.includes(part.type)) {
      return `${exp}[0-9]{${part.value.length}}`;
    }

    return `${exp}${escapeRegExp(part.value)}`;
  }, '');

  return new RegExp(`^(${expression})?$`).test(date);
};

export const dateFormatRule = (date: string, setting: string) =>
  isDateFormatValid(date, setting) ||
  `Date must be in the format of ${datefnsMask(setting).toUpperCase()}`;

export const isDateValid = (date: string, setting: string) => {
  const format = datefnsMask(setting);

  return !date.length || isValid(parse(date, format, new Date()));
};

export const dateValidRule = (date: string, setting: string) =>
  isDateValid(date, setting) || 'Date must be valid';

export const dateRangeValid = ({
  dateISOString,
  minDateISOString,
  maxDateISOString,
}) =>
  new Date(dateISOString) >= new Date(minDateISOString) &&
  new Date(dateISOString) <= new Date(maxDateISOString);

export const dateRangeValidRule = ({
  dateISOString,
  minDateISOString,
  maxDateISOString,
  i18nDateSetting,
}) => {
  return (
    dateRangeValid({ dateISOString, minDateISOString, maxDateISOString }) ||
    `Date must be between ${formatISODate(
      minDateISOString,
      i18nDateSetting,
    )} and ${formatISODate(maxDateISOString, i18nDateSetting)}`
  );
};
