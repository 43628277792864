export const noDataIndicator = '--';
export const defaultAlertMessage =
  'Something went wrong. Please try again, or try contacting your Customer Success representative.';
export const content = {
  en: {
    language: {
      en: 'English',
      es: 'Spanish',
    },
    activeCaregiver: {
      t: 'Yes',
      f: 'No',
    },
    patientStatus: {
      in: 'Opted-In',
      out: 'Opted-Out',
    },
    chatStatus: {
      pending: 'Pending',
      started: 'Started',
      qualified: 'Qualified',
      /* eslint-disable @typescript-eslint/camelcase */
      patient_completed: 'Patient Completed',
      system_completed: 'System Completed',
      /* eslint-enable @typescript-eslint/camelcase */
      ignored: 'Ignored',
      abandoned: 'Abandoned',
    },
  },
  es: {
    language: {
      en: 'Inglés',
      es: 'Español',
    },
    activeCaregiver: {
      t: 'Sí',
      f: 'No',
    },
  },
};
