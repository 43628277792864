


















import { defineComponent } from '@vue/composition-api';
import { getNavOptions } from './nav-options';
import ProfileButton from './ProfileButton.vue';

export default defineComponent({
  components: { ProfileButton },
  setup() {
    return {
      options: getNavOptions(),
    };
  },
});
