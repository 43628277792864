import {
  OrganizationsLoaded,
  OrganizationChanged,
} from '@/organizations/+state/events';
import { organizationsStateKey } from '@/organizations/+state/store';
import { routerStateKey } from '@/router/+state/store';
import { isAxiosError, Payload } from '@/shared';
import { axiosWithAuth } from '@/shared/axios-with-auth';
import { sortColMap } from '@/shared/sort-col-map';
import { UserAccessTokenInvalid } from '@/user/+state/events';
import { createSink } from '@conversa/sink';
import { downloadFromApi } from '@/shared/download-from-api';
import { VistaProjections } from '@/sink';
import { FeedbackFiltersPayload } from '../models';
import { FeedbackState } from './../models/feedback-state.interface';
import {
  FeedbackPageUpdated,
  FeedbackSearchFailed,
  FeedbackTableMounted,
  FeedbackTableSorted,
  FeedbackTableParamChanged,
  FeedbackExportClicked,
  FeedbackExportFailed,
  FeedbackExportFailedDialogDismissed,
} from './events';
import { feedbackInitialState } from './store';

export const feedbackFetchSink = createSink<
  FeedbackState,
  ReturnType<
    | typeof FeedbackTableMounted
    | typeof FeedbackTableParamChanged
    | typeof OrganizationsLoaded
    | typeof OrganizationChanged
  >
>({
  sources: [
    FeedbackTableMounted,
    FeedbackTableParamChanged,
    OrganizationsLoaded,
    OrganizationChanged,
  ],
  gate: ({ getStore }) => {
    const isFeedbackRoute =
      getStore(routerStateKey).route?.path === '/feedback';
    const hasSelectedOrgId = !!getStore(organizationsStateKey).selectedOrgId;

    return isFeedbackRoute && hasSelectedOrgId;
  },
  async sink({ store, broadcast, getStore }) {
    /* eslint-disable @typescript-eslint/camelcase */
    const token = getStore('user').accessToken;
    const org_id = getStore(organizationsStateKey).selectedOrgId;

    const payload: Payload<FeedbackFiltersPayload> = {
      pagination: {
        with_count: true,
        sort_col: sortColMap.get(store.sortCol),
        sort_dir: store.sortDir,
        page: store.page,
      },
      filters: {
        org_id,
      },
      /* eslint-enable @typescript-eslint/camelcase */
    };

    try {
      store.loading = true;
      const { data } = await axiosWithAuth(token, broadcast).post(
        '/api/vista/feedback/search',
        payload,
      );

      store.data = data.data;
      store.count = data.count;
      store.loading = false;
    } catch (error) {
      store.loading = false;
      if (!isAxiosError(error)) {
        console.log(error);
        return;
      }

      store.error = error.response.data;

      broadcast(
        FeedbackSearchFailed({
          payload,
          status: error.response.status,
          message: error.response.data,
        }),
      );
    }
  },
});

export const clearFeedbackState = createSink<
  FeedbackState,
  ReturnType<
    typeof UserAccessTokenInvalid | typeof FeedbackExportFailedDialogDismissed
  >
>({
  sources: [UserAccessTokenInvalid],
  async sink({ store }) {
    store.data = feedbackInitialState.data;
    store.error = feedbackInitialState.error;
    store.count = feedbackInitialState.count;
  },
});

export const feedbackTableSortedSink = createSink<
  FeedbackState,
  ReturnType<typeof FeedbackTableSorted>
>({
  sources: [FeedbackTableSorted],
  sink({ store, event, broadcast }) {
    store.sortCol = event.payload.col;
    store.sortDir = event.payload.dir;
    broadcast(FeedbackTableParamChanged({ origin: 'sort' }));
  },
});

export const feedbackPageUpdated = createSink<
  FeedbackState,
  ReturnType<typeof FeedbackPageUpdated>
>({
  sources: [FeedbackPageUpdated],
  sink({ store, event, broadcast }) {
    store.page = event.payload.page;
    broadcast(FeedbackTableParamChanged({ origin: 'paginate' }));
  },
});

export const feedbackExportClicked = createSink<
  FeedbackState,
  ReturnType<typeof FeedbackExportClicked>
>({
  sources: [FeedbackExportClicked],
  async sink({ store, broadcast, getStore, select }) {
    /* eslint-disable @typescript-eslint/camelcase */
    const token = getStore('user').accessToken;

    const payload: Payload<FeedbackFiltersPayload> = {
      pagination: {
        page: store.page,
        sort_col: sortColMap.get(store.sortCol),
        sort_dir: store.sortDir,
      },
      filters: {
        ...select<keyof VistaProjections>('feedback.search-payload').value,
      },
    };
    /* eslint-enable @typescript-eslint/camelcase */

    downloadFromApi(
      '/api/vista/feedback/search.csv',
      payload,
      store,
      token,
      broadcast,
      FeedbackExportFailed,
    );
  },
});
