import { Ref, watchEffect } from '@vue/composition-api';

export function closeNavOnClickOutEffect(mobileNavOpen: Ref<boolean>) {
  const closeNavOnClickOut = (e: MouseEvent) => {
    const header = document.querySelector('header');
    const headerRect = header.getBoundingClientRect();
    const clickedRect = (e.target as HTMLElement).getBoundingClientRect();
    if (
      clickedRect.top > headerRect.height ||
      clickedRect.height > headerRect.height
    ) {
      mobileNavOpen.value = false;
    }
  };

  watchEffect(() => {
    if (mobileNavOpen.value) {
      window.addEventListener('click', closeNavOnClickOut);
      window.addEventListener('touchmove', closeNavOnClickOut);
    } else {
      window.removeEventListener('click', closeNavOnClickOut);
      window.removeEventListener('touchmove', closeNavOnClickOut);
    }
  });
}
