import { createModule } from '@conversa/sink';
import { RouterState } from '../models';
import * as sinks from './sinks';
import * as projections from './projections';
import { routerStore as store } from './store';

export * from './projections-map';
export * from './events';
export const routerModule = createModule<RouterState>({
  projections,
  sinks,
  store,
});
