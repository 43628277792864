import { handleErrorMessage } from '@/shared/utils';
import decode from 'jwt-decode';
import { AccessTokenDecoded, IdTokenDecoded } from '../models';
import { AccountInfo } from '../models/account-info';
import { userProjector } from './projections-map';

export const getDecodedAccessToken = userProjector<AccessTokenDecoded>(
  'user.tokens.access.decoded',
  ({ store }) => {
    return store.accessToken && decode<AccessTokenDecoded>(store.accessToken);
  },
);

export const accountInfo = userProjector<AccountInfo>(
  'user.account-info',
  ({ select }) => {
    const token = select('user.tokens.access.decoded')
      .value as AccessTokenDecoded;
    const claim = token['http://conversahealth.com/dashboard'];
    /* eslint-disable @typescript-eslint/camelcase */
    return {
      email: claim.email || '--',
      family_name: claim.family_name || '--',
      given_name: claim.given_name || '--',
      member_of: claim.member_of || '--',
      phone: claim.phone || '--',
      provider_code: claim.provider_code || '--',
      scope: token.scope || '--',
      sub: token.sub || '--',
    };
    /* eslint-enable @typescript-eslint/camelcase */
  },
);

export const getDecodedIdToken = userProjector<IdTokenDecoded>(
  'user.tokens.id.decoded',
  ({ store }) => {
    return store.idToken && decode<IdTokenDecoded>(store.idToken);
  },
);

export const userStoreErrorMessage = userProjector<string>(
  'user.error-message',
  handleErrorMessage,
);
