import { PatientUpdatePayload } from '@/patients/models';
import { ErrorState } from '@/shared';
import { ValidationError } from '@/shared/models/validation-errors.interface';
import { action, payload } from 'ts-action';

export const PatientProfileMounted = action(
  '[ component | PatientProfile ] Mounted',
);

export const EditPatientDialogSave = action(
  '[ component | EditPatientDialog ] Save',
  payload<PatientUpdatePayload>(),
);

export const PatientDetailsFailed = action(
  '[ api | vista/patients/:id ] Patient Details Failed',
  payload<{
    status: number;
    // eslint-disable-next-line
    message: any;
  }>(),
);

export const PatientDetailsFailedDialogDismissed = action(
  '[ component | PatientDetailsFailedDialog ] Dismissed',
);

export const EditPatientInvalid = action(
  '[ api | vista/patients/:id ] Edit Patient Invalid',
  payload<{
    payload: PatientUpdatePayload;
    status: number;
    errors: ValidationError[];
  }>(),
);

export const EditPatientFailed = action(
  '[ api | vista/patients/:id ] Edit Patient Failed',
  payload<{
    payload: PatientUpdatePayload;
    error: ErrorState;
  }>(),
);

export const PatientEdited = action(
  '[ api | vista/patients/:id ] Patient Edited',
);
