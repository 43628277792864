export const genderOptions = [
  {
    value: 'f',
    text: 'Female',
  },
  {
    value: 'm',
    text: 'Male',
  },
  {
    value: 'u',
    text: 'Unknown',
  },
];

export const genderMap: Record<string, string> = genderOptions.reduce(
  (map, option) => {
    map[option.value] = option.text;
    return map;
  },
  {},
);

export function humanReadableGender(genderValue: string): string | null {
  if (genderValue in genderMap) {
    return genderMap[genderValue];
  }

  return null;
}
