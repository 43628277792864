

























import { defineComponent } from '@vue/composition-api';
import sink from '@/sink';

export default defineComponent({
  setup() {
    return {
      info: sink.select('user.account-info'),
    };
  },
});
