import { createStoreV2 } from '@conversa/sink';
import { PatientsDetailState } from '../models/state.interface';

export const patientsDetailStateKey = 'patients-detail';

export const patientsDetailInitialState: PatientsDetailState = {
  statusLoading: false,
  profileLoading: false,
  error: null,
  data: null,
};

export const patientsDetailStore = createStoreV2(
  patientsDetailStateKey,
  patientsDetailInitialState,
);
