









































































import {
  defineComponent,
  onBeforeUnmount,
  ref,
  watchEffect,
  watch,
} from '@vue/composition-api';
import { EditUserDialogSave, userModule } from '@/user/+state';
import sink from '@/sink';
import { VueTelInput } from 'vue-tel-input';
import {
  TelInputValidEvent,
  TelInputCountryChangeEvent,
} from '@/shared/models/preferred-communication-event.interface';

export default defineComponent({
  components: { VueTelInput },
  setup(props, { emit }) {
    const user = userModule.injectStore();
    const contactPhone = ref(null);
    const mobilePhone = ref(null);
    const alertThreshold = ref(null);
    const alertNotificationMethod = ref(null);
    const isFormValid = ref(true);
    const form = ref(null);
    const mobilePhoneInputModel = ref(null);
    const contactPhoneInputModel = ref(null);
    const mobilePhoneData = ref({
      valid: true,
      countryCode: null,
      number: null,
      countryCallingCode: null,
    });
    const contactPhoneData = ref({
      valid: true,
      countryCode: null,
      number: null,
      countryCallingCode: null,
    });

    const watchStopHandler = watchEffect(() => {
      const payload = {
        contactPhone: contactPhone.value,
        mobilePhone: mobilePhone.value === '' ? null : mobilePhone.value,
        alertThreshold: alertThreshold.value,
        alertNotificationMethod: alertNotificationMethod.value,
      };

      emit(
        'formStatusDiff',
        (() => {
          for (const key in payload) {
            if (payload[key] !== user[key]) return true;
          }
          return false;
        })(),
      );
    });

    watch(
      () => isFormValid.value && mobilePhoneData.value.valid,
      newValue => emit('isFormValid', newValue),
    );

    watch(
      () => isFormValid.value && contactPhoneData.value.valid,
      newValue => emit('isFormValid', newValue),
    );

    onBeforeUnmount(() => {
      watchStopHandler();
    });

    contactPhone.value = user.contactPhone;
    mobilePhone.value = user.mobilePhone;
    alertThreshold.value = user.alertThreshold;
    alertNotificationMethod.value = user.alertNotificationMethod;
    mobilePhoneInputModel.value = mobilePhone.value;
    contactPhoneInputModel.value = contactPhone.value;

    const onAlertThresholdChange = value => {
      alertThreshold.value = value || null;
      if (!alertThreshold.value) alertNotificationMethod.value = null;
      form.value.validate();
    };

    const phoneTypeMap = {
      mobile: {
        phone: mobilePhone,
        phoneData: mobilePhoneData,
        phoneInputModel: mobilePhoneInputModel,
      },
      contact: {
        phone: contactPhone,
        phoneData: contactPhoneData,
        phoneInputModel: contactPhoneInputModel,
      },
    };

    const onPhoneCorrectnessChanged = (
      event: TelInputValidEvent,
      phoneType: string,
    ) => {
      if (event.number && event.valid) {
        phoneTypeMap[phoneType].phone.value = event.number;
        phoneTypeMap[phoneType].phoneData.value = event;
        form.value.validate();
      } else {
        phoneTypeMap[phoneType].phone.value = user[`${phoneType}Phone`];
        phoneTypeMap[phoneType].phoneData.value.valid = false;
      }
    };

    const onPhoneInputChange = (phoneNumber: string, phoneType: string) => {
      if (phoneNumber.length === 0) {
        phoneTypeMap[phoneType].phoneData.value.valid = true;
        phoneTypeMap[phoneType].phone.value = phoneNumber;
      } else if (phoneNumber.length < 2) {
        phoneTypeMap[phoneType].phoneData.value.valid = false;
      }
    };

    const onCountryChange = (
      event: TelInputCountryChangeEvent,
      phoneType: string,
    ) => {
      if (!phoneTypeMap[phoneType].phoneInputModel.value.length) {
        phoneTypeMap[phoneType].phoneData.value.valid = true;
      }
      phoneTypeMap[phoneType].phoneData.value.countryCode = event.iso2;
      phoneTypeMap[phoneType].phone.value =
        phoneTypeMap[phoneType].phoneInputModel.value;
    };

    const onAlertNotificationMethodChange = value => {
      alertNotificationMethod.value = value || null;
      form.value.validate();
    };

    return {
      form,
      save: () => {
        const inputs = {
          contactPhone: contactPhone.value
            ? `${contactPhone.value}|country|=${contactPhoneData.value.countryCode}`
            : null,
          mobilePhone: mobilePhone.value
            ? `${mobilePhone.value}|country|=${mobilePhoneData.value.countryCode}`
            : null,
          alertThreshold: alertThreshold.value,
          alertNotificationMethod: alertNotificationMethod.value,
        };
        sink.broadcast(EditUserDialogSave(inputs));
      },
      mobilePhoneInputModel,
      contactPhoneInputModel,
      isFormValid,
      mobilePhoneData,
      contactPhoneData,
      user,
      contactPhone,
      mobilePhone,
      alertThreshold,
      alertNotificationMethod,
      onPhoneInputChange,
      onCountryChange,
      onAlertNotificationMethodChange,
      onAlertThresholdChange,
      onPhoneCorrectnessChanged,
      alertOptions: [
        { value: 'none', text: 'All Responses' },
        { value: 'green', text: 'All Color Responses' },
        { value: 'yellow', text: 'Yellow and Red' },
        { value: 'red', text: 'Red' },
      ],
      notificationOptions: [
        { value: 'email', text: 'Email' },
        { value: 'sms', text: 'Text' },
      ],
    };
  },
});
