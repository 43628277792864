import { TableMessage } from './../../shared/empty-table-messages';
import { ErrorState, ActivitiesSortCol, SortDir } from '@/shared';
import { createProjector } from '@conversa/sink';
import {
  ActivitiesFilters,
  ActivitiesState,
  ActivitiesTable,
  ActivitiesSearchFiltersPayload,
} from '../models';

export interface ActivitiesProjectionsMap {
  'activities.filters': ActivitiesFilters;
  'activities.loading': boolean;
  'activities.table': ActivitiesTable;
  'activities.search-payload': ActivitiesSearchFiltersPayload;
  'activities.error': ErrorState;
  'activities.sort-col': ActivitiesSortCol;
  'activities.sort-dir': SortDir;
  'activities.filters-dirty': boolean;
  'activities.page-number': number;
  'activities.count': number;
  'activities.tableMessage': TableMessage;
}

export const activitiesProjector = createProjector<
  ActivitiesProjectionsMap,
  ActivitiesState
>();
