export const deeplinkViewList = ['patientDetail'] as const;
export type DeeplinkView = typeof deeplinkViewList[number];

export interface Deeplink {
  org: {
    id: string;
    idNumeric: number;
  };

  patientId: string;
  patientIdNumeric: number;
  view: DeeplinkView;
}
