import { FeedbackSortCol, SortDir } from '@/shared';
import { createProjector } from '@conversa/sink';
import {
  FeedbackState,
  FeedbackTable,
  FeedbackFiltersPayload,
} from '../models';

export interface FeedbackProjectionsMap {
  'feedback.loading': boolean;
  'feedback.table': FeedbackTable;
  'feedback.sort-col': FeedbackSortCol;
  'feedback.sort-dir': SortDir;
  'feedback.page-number': number;
  'feedback.count': number;
  'feedback.search-payload': FeedbackFiltersPayload;
}

export const feedbackProjector = createProjector<
  FeedbackProjectionsMap,
  FeedbackState
>();
