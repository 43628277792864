import { createStoreV2 } from '@conversa/sink';
import { PatientsDetailSummaryEnrollmentsState } from '../models';

export const patientsDetailSummaryEnrollmentsStateKey =
  'patients-detail-summary-enrollments-state';

export const patientDetailSummaryEnrollmentsInitialState: PatientsDetailSummaryEnrollmentsState = {
  loading: false,
  error: null,
  enrollments: [],
  surveys: [],
  selectedSurveyId: null,
  selectedEnrollmentId: null,
};

export const patientDetailSummaryEnrollmentsStore = createStoreV2(
  patientsDetailSummaryEnrollmentsStateKey,
  patientDetailSummaryEnrollmentsInitialState,
);
