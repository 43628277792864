import { reportsProjector } from '@/reporting/+state/projections-map';
import { AvailableReport } from '@/reporting/models';
import { VuetifySelectOption } from '@/shared/models/select-options.interface';
import { DynamicFilter } from '@/shared/models/dynamic-filter.interface';
import { ErrorState } from '@/shared';

export const loadingAvailableReport = reportsProjector<boolean>(
  'reports.available-loading',
  ({ store }) => store.loadingAvailable,
);

export const availableReportsCount = reportsProjector<number>(
  'reports.available-count',
  ({ store }) => store.reportsAvailable?.length ?? 0,
);

export const availableReports = reportsProjector<AvailableReport[]>(
  'reports.available',
  ({ store }) => store.reportsAvailable,
);

export const availableReportsOptions = reportsProjector<VuetifySelectOption[]>(
  'reports.available-options',
  ({ store }) =>
    store.reportsAvailable.map(report => ({
      text: report.name,
      value: report.id,
    })),
);

export const reportId = reportsProjector<string>(
  'report.id',
  ({ store }) => store.selectedReport,
);
export const reportUrl = reportsProjector<string>(
  'report.url',
  ({ store }) => store.url,
);

export const loadingReport = reportsProjector<boolean>(
  'report.loading',
  ({ store }) => store.loading,
);

export const reportFiltersDirty = reportsProjector<boolean>(
  'report.filters-dirty',
  ({ store }) => store.filtersDirty,
);

export const reportFilters = reportsProjector<Record<string, string>>(
  'report.filters',
  ({ store }) => store.filters,
);

export const reportFiltersRaw = reportsProjector<DynamicFilter[]>(
  'report.filters-raw',
  ({ store }) => store.filtersRaw,
);

export const reportErrorState = reportsProjector<ErrorState>(
  'report.error-state',
  ({ store }) => store.error,
);

export const reportsAvailableErrorState = reportsProjector<ErrorState>(
  'reports.available-error-state',
  ({ store }) => store.availableError,
);
