


















import { AppMounted } from '@/+state';
import DialogContainer from '@/dialog/DialogContainer.vue';
import sink from '@/sink';
import {
  DialogProvider,
  DIALOG_TOKEN,
  DialogLauncher,
} from '@conversa/bedazzled/src/dialog';
import {
  defineComponent,
  onMounted,
  provide,
  watch,
} from '@vue/composition-api';
import AppBar from './app-bar/AppBar.vue';
import { RouteChanged } from './router/+state';

export default defineComponent({
  components: {
    AppBar,
    DialogLauncher,
  },
  setup(_, context) {
    sink.initialize();
    onMounted(sink.lazyBroadcast(AppMounted()));

    watch(
      () => context.root.$route.fullPath,
      (value, oldValue) => {
        if (value === oldValue) return;
        sink.broadcast(RouteChanged({ route: context.root.$route }));
      },
    );

    provide(DIALOG_TOKEN, DialogProvider());

    return {
      dialogContainer: DialogContainer,
    };
  },
});
