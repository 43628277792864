import { CaregiverProjection } from '@/patients/models';
import { handleErrorMessage } from '@/shared';
import { createProjector } from '@conversa/sink';
import {
  CaregiverStatusDialogText,
  PatientsDetailSummaryCaregiverState,
} from '../models';

export interface PatientsDetailSummaryCaregiverProjectionMap {
  'patients.detail.summary.caregiver': CaregiverProjection;
  'patients.details.summary.status-dialog-text': CaregiverStatusDialogText;
  'patients.details.summary.caregiver-dialog.loading': boolean;
  'patients.details.summary.caregiver-dialog.error-message': string;
}

const projector = createProjector<
  PatientsDetailSummaryCaregiverProjectionMap,
  PatientsDetailSummaryCaregiverState
>();

export const caregiverDialogLoading = projector<boolean>(
  'patients.details.summary.caregiver-dialog.loading',
  ({ store }) => store.loading,
);

export const caregiverDialogError = projector<string>(
  'patients.details.summary.caregiver-dialog.error-message',
  handleErrorMessage,
);

export const activeCaregiver = projector<CaregiverProjection>(
  'patients.detail.summary.caregiver',
  ({ store }) => {
    const caregiver =
      store.data.find(cg => cg.active === true) || store.data[0];

    return caregiver
      ? {
          name: `${caregiver.first_name || '--'} ${caregiver.last_name ||
            '--'}`,
          firstName: caregiver.first_name,
          lastName: caregiver.last_name,
          mobilePhone: caregiver.mobile_phone,
          email: caregiver.email,
          preferredLanguage: caregiver.preferred_language,
          preferredCommunicationChannel:
            caregiver.preferred_communication_channel,
          active: caregiver.active,
        }
      : null;
  },
);

export const caregiverStatus = projector<CaregiverStatusDialogText>(
  'patients.details.summary.status-dialog-text',
  ({ select }) => {
    return (select('patients.detail.summary.caregiver')
      .value as CaregiverProjection).active
      ? {
          title: 'Deactivate Caregiver?',
          description:
            'Are you sure you want to deactivate the Caregiver? All program communications  will now be sent to the Patient.',
          button: 'Deactivate',
          loading: 'Deactivating Cargiver...',
        }
      : {
          title: 'Activate Caregiver?',
          description:
            'Are you sure you want to activate the Caregiver? All program communications  will stop being sent to the Patient and will be sent to the Caregiver.',
          button: 'Activate',
          loading: 'Activating Caregiver...',
        };
  },
);
