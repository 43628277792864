import { ColorStatus, SortDir, TableMessage } from '@/shared';
import { dateFormatter } from '../../../../../../../shared/date-formatter';
import { createProjector } from '@conversa/sink';
import { PatientDetailsCompletedChatRow } from '../models/completed-chat.interface';
import { PatientDetailsCompletedChatsSortedCol } from '../models/sorted-col.interface';
import { PatientDetailsCompletedChatsState } from '../models/store.interface';
import { capabilitiesStateKey } from '@/capabilities/+state/store';
import { CapabilitiesState } from '@conversa/bedazzled/src/models/capabilities-state.interface';

export interface PatientDetailsCompletedChatsProjectionMap {
  'patient.details.completed-chats': PatientDetailsCompletedChatRow[];
  'patient.details.completed-chats.loading': boolean;
  'patient.details.completed-chats.page.number': number;
  'patient.details.completed-chats.count': number;
  'patient.details.completed-chats.sorted-col': PatientDetailsCompletedChatsSortedCol;
  'patient.details.completed-chats.sort-dir': SortDir;
  'patient.details.completed-chats.expanded-rows': PatientDetailsCompletedChatRow[];
  'patient.details.completed-chats.table-message': TableMessage;
}

const projector = createProjector<
  PatientDetailsCompletedChatsProjectionMap,
  PatientDetailsCompletedChatsState
>();

export const patientDetailsCompletedChats = projector<
  PatientDetailsCompletedChatRow[]
>('patient.details.completed-chats', ({ store, getStore }) => {
  const cStore = getStore(capabilitiesStateKey) as CapabilitiesState;
  return store.data.map(completedChat => {
    return {
      checkupId: +completedChat.checkup_id,
      chatDate: dateFormatter(
        completedChat.chat_date,
        cStore.capabilities.international.datetimeFormat,
        'dateTime',
      ),
      programModule: completedChat.chat_name,
      chatStatus: completedChat.chat_status as ColorStatus,
      responses: completedChat.responses.map(response => {
        return {
          id: response.id,
          question: response.question_text,
          response: response.response_text,
          responseStatus: response.response_status,
        };
      }),
      responsesLoading: completedChat.responsesLoading,
      responsesTableMessage: completedChat.responsesTableMessage,
    };
  });
});

export const patientDetailsCompletedChatsLoading = projector<boolean>(
  'patient.details.completed-chats.loading',
  ({ store }) => {
    return store.loading;
  },
);

export const patientDetailsCompletedChatsPageNumber = projector<number>(
  'patient.details.completed-chats.page.number',
  ({ store }) => {
    return store.page;
  },
);

export const patientDetailsCompletedChatsCount = projector<number>(
  'patient.details.completed-chats.count',
  ({ store }) => {
    return store.count;
  },
);

export const patientDetailsCompletedChatsSortedCol = projector<
  PatientDetailsCompletedChatsSortedCol
>('patient.details.completed-chats.sorted-col', ({ store }) => {
  return store.sortedCol;
});

export const patientDetailsCompletedChatsSortDir = projector<SortDir>(
  'patient.details.completed-chats.sort-dir',
  ({ store }) => {
    return store.sortDir;
  },
);

export const patientDetailsCompletedChatsExpandedRows = projector<
  PatientDetailsCompletedChatRow[]
>('patient.details.completed-chats.expanded-rows', ({ store }) => {
  return store.exRows;
});

export const patientDetailsCompletedChatsTableMessage = projector<TableMessage>(
  'patient.details.completed-chats.table-message',
  ({ store }) => {
    return store.tableMessage;
  },
);
