

















import { userModule } from '@/user';
import { defineComponent } from '@vue/composition-api';
import { DialogInstance } from '@conversa/bedazzled/src/dialog';
import UserProfileDialog from '@/user/dialogs/profile/ProfileDialog.vue';

export default defineComponent({
  props: {
    label: {
      type: String,
      default: '',
    },
  },
  setup() {
    return {
      profileClicked: () => DialogInstance.open(UserProfileDialog),
      user: userModule.injectStore(),
    };
  },
});
