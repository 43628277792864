import { createStoreV2 } from '@conversa/sink';
import { FeedbackState } from '../models';

export const feedbackStateKey = 'feedback-state';

export const feedbackInitialState: FeedbackState = {
  data: [],
  error: null,
  count: null,
  loading: false,
  page: 1,
  sortCol: 'feedbackDate',
  sortDir: 'desc',
  filters: null,
  filtersDirty: null,
};

export const feedbackStore = createStoreV2(
  feedbackStateKey,
  feedbackInitialState,
);
