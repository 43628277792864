import { createModule } from '@conversa/sink';
import { patientDetailsResponsesStore as store } from './store';
import { PatientDetailsResponsesState } from '../../models';
import * as projections from './projections';
import * as sinks from './sinks';

export const patientDetailsResponsesModule = createModule<
  PatientDetailsResponsesState
>({
  projections,
  sinks,
  store,
});
