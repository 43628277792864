import { AppMounted } from '@/+state/events';
import { CapabilitiesLoaded } from '@/capabilities';
import router from '@/router';
import { getLastRoute } from '@/shared';
import { handleApiError } from '@/shared/api/set-store-error';
import { axiosWithAuth } from '@/shared/axios-with-auth';
import { isAxiosError } from '@/shared/isAxiosError';
import { UserState } from '../models';
import {
  EditUserDialogSave,
  InactivityThresholdMet,
  InactivityWarningThresholdMet,
  LogoutButtonClicked,
  ProfileFetched,
  ProfileFetchErrorDialogDismissed,
  ProfileFetchFailed,
  ProfileUpdated,
  ProfileUpdateFailed,
  SessionEndingSoonThresholdMet,
  SessionExpired,
  UserAccessTokenInvalid,
  UserAccessTokenSet,
  UserIdTokenSet,
  UserNoValidOrgs,
  UserProfileSet,
  UserAccessTokenInvalidCancelled,
  UserAccessTokenMissing,
} from './events';
import { createSink } from '@conversa/sink';
import { timer } from 'rxjs';
import { filter, startWith, switchMapTo, takeUntil } from 'rxjs/operators';
import { isType } from 'ts-action';
import { ofType } from 'ts-action-operators';
import { AccessTokenDecoded, UserProfile } from '../models';

const sessionErrorMessageKey = 'vista-sso-error';

interface BaseLoadSaveStatus {
  fromStorage: boolean;
  fromParams: boolean;
}

interface SsoErrorLoadSaveStatus extends BaseLoadSaveStatus {
  message: string;
  hasMessage: boolean;
}

function loadOrSaveSsoError(
  errMsg: string | undefined | null,
): SsoErrorLoadSaveStatus {
  if (errMsg) {
    sessionStorage.setItem(sessionErrorMessageKey, errMsg);

    return {
      message: errMsg,
      hasMessage: !!errMsg?.length,
      fromStorage: false,
      fromParams: true,
    };
  }

  const message = sessionStorage.getItem(sessionErrorMessageKey);

  return {
    message,
    hasMessage: !!message?.length,
    fromStorage: true,
    fromParams: false,
  };
}

interface TokenLoadSaveStatus extends BaseLoadSaveStatus {
  token: string;
  hasToken: boolean;
}

function loadOrSaveToken(key, token): TokenLoadSaveStatus {
  const sessionStateKey = `vista-${key}-token`;

  if (token?.length) {
    sessionStorage.setItem(sessionStateKey, token);
    return {
      token,
      hasToken: !!token?.length,
      fromStorage: false,
      fromParams: true,
    };
  }

  const savedToken = sessionStorage.getItem(sessionStateKey);

  return {
    token: savedToken,
    hasToken: !!savedToken?.length,
    fromStorage: true,
    fromParams: false,
  };
}

export function handleSsoError(store, errorMessage) {
  store.error = {
    code: null,
    message: errorMessage,
    details: null,
  };
}

export const clearUsersOnInvalidUserToken = createSink<
  UserState,
  ReturnType<typeof UserAccessTokenInvalid>
>({
  sources: [UserAccessTokenInvalid],
  async sink({ store }) {
    store.accessToken = null;
    store.idToken = null;
    sessionStorage.removeItem('vista-access-token');
    sessionStorage.removeItem('vista-id-token');
  },
});

export const grabTokenFromUri = createSink<
  UserState,
  ReturnType<typeof AppMounted>
>({
  sources: [AppMounted],
  async sink({ store, broadcast }) {
    const params = new URLSearchParams(location.hash.slice(1));
    router.replace(getLastRoute() || '/', () => {
      history.replaceState(null, '', location.origin);
    });

    const accessTokenParam = params.get('access_token');
    const accessTokenData = loadOrSaveToken('access', accessTokenParam);
    const idTokenData = loadOrSaveToken('id', params.get('id_token'));

    const errorMessageParam = params.get('error_message');

    // Remove any saved SSO error messages if we got new access tokens on the
    // url but no new error message
    if (accessTokenParam?.length && !errorMessageParam?.length) {
      sessionStorage.removeItem(sessionErrorMessageKey);
      store.error = null;
    } else {
      const ssoErrorData = loadOrSaveSsoError(errorMessageParam);

      if (ssoErrorData.hasMessage) {
        handleSsoError(store, ssoErrorData.message);
        console.warn(`SSO Error ${store.error.message}`);
      }
    }

    if (accessTokenData.hasToken) {
      store.accessToken = accessTokenData.token;
      broadcast(UserAccessTokenSet());
    } else {
      // This is the fallback should no other event want to do other things before this lands
      // but only on app start
      store.invalidTokenTimeout = setTimeout(() => {
        broadcast(UserAccessTokenInvalid());
      }, 1000);

      broadcast(UserAccessTokenMissing());
    }

    if (idTokenData.hasToken) {
      store.idToken = idTokenData.token;
      broadcast(UserIdTokenSet());
    }
  },
});

export const showInvalidOnUserNoValidOrgs = createSink<
  UserState,
  ReturnType<typeof UserNoValidOrgs>
>({
  sources: [UserNoValidOrgs],
  async sink({ store, broadcast }) {
    handleSsoError(
      store,
      'User has no valid organizations. Please try again, or try contacting your Customer Success representative.',
    );
    sessionStorage.setItem(sessionErrorMessageKey, store.error.message);

    broadcast(UserAccessTokenInvalid());
  },
});

export const loadProfileSink = createSink<
  UserState,
  ReturnType<typeof UserAccessTokenSet>
>({
  sources: [CapabilitiesLoaded],
  async sink({ store, broadcast }) {
    const token = store.accessToken;

    try {
      const { data } = await axiosWithAuth(token, broadcast).get<UserProfile>(
        '/api/vista/users/profile',
      );

      broadcast(ProfileFetched({ data }));
    } catch (error) {
      console.warn(error);

      if (!isAxiosError(error)) {
        return;
      }

      handleApiError(store, error);
      broadcast(ProfileFetchFailed());
    }
  },
});

export const clearUserStoreError = createSink<
  UserState,
  ReturnType<typeof ProfileFetchErrorDialogDismissed>
>({
  sources: [ProfileFetchErrorDialogDismissed],
  sink({ store }) {
    store.error = null;
  },
});

export const logoutSink = createSink<
  UserState,
  ReturnType<typeof LogoutButtonClicked>
>({
  sources: [LogoutButtonClicked, InactivityThresholdMet, SessionExpired],
  sink({ select }) {
    sessionStorage.removeItem('vista-access-token');
    sessionStorage.removeItem('vista-id-token');
    // prettier-ignore
    const token: AccessTokenDecoded = select('user.tokens.access.decoded').value;

    const redirectUri = new URL(
      `${window.location.protocol}//${window.location.host}`,
    );

    const returnTo = new URL('/authorize', token.iss);
    returnTo.searchParams.append('response_type', 'token');
    returnTo.searchParams.append('client_id', token.azp);
    returnTo.searchParams.append(
      'audience',
      Array.isArray(token.aud)
        ? token.aud.find(s => s.indexOf('dashboard') >= 0)
        : token.aud,
    );
    returnTo.searchParams.append(
      'connection',
      token['http://conversahealth.com/dashboard'].connection_name,
    );
    returnTo.searchParams.append('scope', token.scope);
    returnTo.searchParams.append('redirect_uri', redirectUri.href);

    const logoutUrl = new URL('/v2/logout', token.iss);
    logoutUrl.searchParams.append('client_id', token.azp);
    logoutUrl.searchParams.append('federated', 'true');
    logoutUrl.searchParams.append('returnTo', returnTo.href);

    window.location.href = logoutUrl.href;
  },
});

export const inactivityWarningTimerSink = createSink<
  UserState,
  ReturnType<typeof ProfileFetched>
>({
  sources: [ProfileFetched],
  sink({ events$, broadcast }) {
    events$
      .pipe(
        startWith("let's get it started!"),
        filter(
          event =>
            !isType(
              event,
              InactivityWarningThresholdMet,
              SessionEndingSoonThresholdMet,
            ),
        ),
        switchMapTo(timer(780000)), // might be good to put this into an API and base it on config
        takeUntil(events$.pipe(ofType(SessionEndingSoonThresholdMet))),
      )
      .subscribe(() => broadcast(InactivityWarningThresholdMet()));
  },
});

export const inactivityTimerSink = createSink<
  UserState,
  ReturnType<typeof InactivityWarningThresholdMet>
>({
  sources: [InactivityWarningThresholdMet],
  sink({ broadcast, events$ }) {
    timer(120000) // might be good to put this into an API and base it on config
      .pipe(takeUntil(events$))
      .subscribe(() => broadcast(InactivityThresholdMet()));
  },
});

export const relogTimerSink = createSink<
  UserState,
  ReturnType<typeof ProfileFetched>
>({
  sources: [ProfileFetched],
  sink({ broadcast, select }) {
    const time = (select('user.tokens.access.decoded').value.exp - 1200) * 1000;
    timer(new Date(time)).subscribe(() =>
      broadcast(SessionEndingSoonThresholdMet()),
    );
  },
});

export const logoutTimerSink = createSink<
  UserState,
  ReturnType<typeof ProfileFetched>
>({
  sources: [ProfileFetched],
  sink({ broadcast, select }) {
    const time = select('user.tokens.access.decoded').value.exp * 1000;
    timer(new Date(time)).subscribe(() => broadcast(SessionExpired()));
  },
});

export const editUserSettings = createSink<
  UserState,
  ReturnType<typeof EditUserDialogSave>
>({
  sources: [EditUserDialogSave],
  async sink({ broadcast, store, event }) {
    const token = store.accessToken;

    try {
      store.loading = true;
      store.error = null;
      const { data } = await axiosWithAuth(token, broadcast).patch<UserProfile>(
        '/api/vista/users/profile',
        event.payload,
      );

      broadcast(ProfileUpdated({ data }));
    } catch (error) {
      console.warn(error);

      if (!isAxiosError(error)) {
        return;
      }

      handleApiError(store, error);
      broadcast(ProfileUpdateFailed());
    } finally {
      store.loading = false;
    }
  },
});

export const setUserInfo = createSink<
  UserState,
  ReturnType<typeof ProfileFetched | typeof ProfileUpdated>
>({
  sources: [ProfileFetched, ProfileUpdated],
  async sink({ broadcast, store, event }) {
    const { data } = event.payload;

    store.id = data.id;
    store.alertThreshold = data.alert_threshold;
    store.alertNotificationMethod = data.alert_notification_method;
    store.email = data.email;
    store.nameFirst = data.name_first;
    store.nameLast = data.name_last;
    store.mobilePhone = data.mobile_phone;
    store.contactPhone = data.contact_phone;

    broadcast(UserProfileSet());
  },
});

export const handleCancelInvalidToken = createSink<
  UserState,
  ReturnType<typeof UserAccessTokenInvalidCancelled>
>({
  sources: [UserAccessTokenInvalidCancelled],
  async sink({ store }) {
    if (store.invalidTokenTimeout) {
      clearTimeout(store.invalidTokenTimeout);
      store.invalidTokenTimeout = null;
    }
  },
});
