import { action, payload } from 'ts-action';
import { ErrorState } from '@conversa/bedazzled/src/models/error-state.interface';
import { ChartDateFilters } from '../models/charting.interface';

export const PatientChartingLoadFailed = action(
  '[ api | vista/patients/:id/charting ] Charting Load Failed',
  payload<{
    error: ErrorState;
  }>(),
);

export const PatientChartingMounted = action(
  '[ component | patient detail charting ] Mounted',
);

export const PatientChartingUnmounted = action(
  '[ component | patient detail charting ] Unmounted',
);

export const ChartFilterChanged = action(
  '[ component | Chart Filters ] Filter Changed',
  payload<{ input: keyof ChartDateFilters; value: string }>(),
);

export const FilterDatesUpdated = action(
  '[ component | Chart Filters ] Filter Updated',
);
