




import sink from '@/sink';
import { defineComponent } from '@vue/composition-api';
import { ErrorDialog } from '@/shared/components';
import { PatientDetailsFailedDialogDismissed } from './+state/events';
import { DialogInstance } from '@conversa/bedazzled/src/dialog';

export default defineComponent({
  components: { ErrorDialog },
  setup() {
    return {
      error: sink.select('patients.detail.error-message'),
      dismissed: () => {
        sink.broadcast(PatientDetailsFailedDialogDismissed());
        DialogInstance.close();
      },
    };
  },
});
