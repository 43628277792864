import { PatientSession } from './patient-session.interface';
import { ErrorState } from './error-state.interface';

export interface PatientsDetailSummarySessionsState {
  data: PatientSession[];
  error: ErrorState;
  loading: boolean;
  page: number;
  viewHistoric: boolean;
}

export const patientsDetailSessionsInitialState: PatientsDetailSummarySessionsState = {
  data: [],
  error: null,
  loading: false,
  page: 1,
  viewHistoric: false,
};
