import { Payload, SortDir, FeedbackSortCol } from '@/shared';
import { action, payload } from 'ts-action';
import { FeedbackFiltersPayload } from '../models';

export const FeedbackSearchFailed = action(
  '[ api | vista/feedback/search ] Feedback Search Failed',
  payload<{
    payload: Payload<FeedbackFiltersPayload>;
    status: number;
    // eslint-disable-next-line
    message: any;
  }>(),
);

export const FeedbackTableSorted = action(
  '[ component | Feedback Table ] Table Sorted',
  payload<{ col: FeedbackSortCol; dir: SortDir }>(),
);

export const FeedbackPageUpdated = action(
  '[ component | Feedback Table ] Page Updated',
  payload<{ page: number }>(),
);

export const FeedbackTableMounted = action(
  '[ component | Feedback Table ] Feedback Table Mounted',
);

export const FeedbackTableParamChanged = action(
  '[ sinks | Feedback ] Feedback Table Param Changed',
  payload<{ origin: 'sort' | 'paginate' }>(),
);

export const FeedbackExportClicked = action(
  '[ component | Feedback Page ] Feedback Export Button Clicked',
);

export const FeedbackExportFailed = action(
  '[ api | vista/feedback/export ] Feedback Export Failed',
  payload<{
    payload: null;
    status: number;
    message: unknown;
  }>(),
);

export const FeedbackExportFailedDialogDismissed = action(
  '[ component | FeedbackExportFailedDialog ] Dismissed',
);
