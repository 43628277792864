import { ReportState } from './../models/report-state';
import { createStoreV2 } from '@conversa/sink';

export const reportStateKey = 'report-state';

export const reportInitialState: ReportState = {
  url: '',
  error: null,
  availableError: null,
  loadingAvailable: false,
  loading: false,
  filtersDirty: false,
  filters: {},
  filtersRaw: [],
  reportsAvailable: [],
  selectedReport: null,
};

export const reportStore = createStoreV2(reportStateKey, reportInitialState);
