import { createModule } from '@conversa/sink';
import { PatientsState } from '../models';
import * as projections from './projections';
import * as sinks from './sinks';
import { patientsStore as store } from './store';

export const patientsModule = createModule<PatientsState>({
  projections,
  sinks,
  store,
});
