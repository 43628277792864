import { createStoreV2 } from '@conversa/sink';
import {
  PatientChart,
  PatientChartData,
  PatientChartOptionAnnotationElementBox,
  PatientChartOptionAnnotationElementWarning,
  PatientChartOptions,
  PatientChartState,
} from '../models';

export const chartingStateKey = 'patient-charting-state';

export const patientChartDataInitialStateBP: PatientChartData = {
  labels: [],
  datasets: [
    {
      label: 'Systolic',
      data: [],
      borderColor: '#1774CC',
      backgroundColor: '#1774CC',
      lineTension: 0.1,
      fill: false,
    },
    {
      label: 'Systolic range',
      data: [],
      borderColor: '#B1D6EF',
      backgroundColor: '#B1D6EF',
      lineTension: 0.1,
      fill: false,
    },
    {
      label: 'Diastolic',
      data: [],
      borderColor: '#C32DC3',
      backgroundColor: '#C32DC3',
      lineTension: 0.1,
      fill: false,
    },
    {
      label: 'Diastolic range',
      data: [],
      borderColor: '#E5A5E5',
      backgroundColor: '#E5A5E5',
      lineTension: 0.1,
      fill: false,
    },
  ],
};

export const patientChartOptionAnnotationWithData: PatientChartOptionAnnotationElementBox[] = [
  {
    // Purplish color filled box, will be used for bands
    id: 'a-box-2',
    type: 'box',
    drawTime: 'beforeDatasetsDraw',
    xScaleID: 'x-axis-0',
    yScaleID: 'y-axis-0',
    yMin: 60,
    yMax: 90,
    backgroundColor: 'rgb(229,165,229,0.5)',
  },
  {
    // Bluish color filled box, will be used for bands
    id: 'a-box-1',
    type: 'box',
    drawTime: 'beforeDatasetsDraw',
    xScaleID: 'x-axis-0',
    yScaleID: 'y-axis-0',
    yMin: 90,
    yMax: 140,
    backgroundColor: 'rgb(177,214,239,0.5)',
  },
];

export const patientChartOptionsInitialStateBP: PatientChartOptions = {
  title: {
    display: true,
    text: 'Blood Pressure',
  },
  legend: {
    display: true,
    position: 'top',
    labels: {
      boxWidth: 10,
      fontColor: 'black',
      useBorderRadius: true,
      borderRadius: 10,
    },
  },
  maintainAspectRatio: true,
  responsive: true,
  scales: {
    xAxes: [
      {
        scaleLabel: {
          display: false,
          labelString: '',
        },
        type: 'time',
        time: {
          unit: 'day',
          unitStepSize: 1,
          tooltipFormat: 'MM/DD/YYYY HH:MM',
          displayFormats: {
            day: 'MM/DD/YYYY',
          },
        },
        bounds: 'ticks',
        ticks: {
          maxTicksLimit: 7,
        },
      },
    ],
    yAxes: [
      {
        scaleLabel: {
          display: true,
          labelString: 'mmHg',
        },
        ticks: {
          min: 20,
          max: 220,
          stepSize: 10,
        },
      },
    ],
  },
  annotation: {
    annotations: patientChartOptionAnnotationWithData,
  },
};

export const patientChartOptionAnnotationNoData: PatientChartOptionAnnotationElementWarning[] = [
  {
    // Draws a Note at the bottom of chart
    // To use, uncomment the 'content' element
    drawTime: 'afterDraw',
    id: 'a-line-1',
    type: 'line',
    mode: 'horizontal',
    scaleID: 'y-axis-0',
    value: 0,
    borderColor: '#E1E1E1',
    borderWidth: 2,
    label: {
      backgroundColor: '#961505',
      fontSize: 18,
      fontStyle: 'bold',
      fontColor: '#fff',
      position: 'center',
      xAdjust: 0,
      yAdjust: -250,
      enabled: true,
      content: 'No Data Available',
    },
  },
];

export const patientChartInitialStateBP: PatientChart = {
  type: 'line',
  name: 'Blood Pressure',
  data: patientChartDataInitialStateBP,
  options: patientChartOptionsInitialStateBP,
};

export const patientChartInitialState: PatientChartState = {
  data: {
    systolic: [],
    diastolic: [],
  },
  error: null,
  loading: false,
  filters: {
    dateFrom: new Date(Date.now() - 6 * 24 * 60 * 60 * 1000).toISOString(), // default to 7 days ago
    dateTo: new Date().toISOString(),
  },
};

export const patientChartingStore = createStoreV2(
  chartingStateKey,
  patientChartInitialState,
);
