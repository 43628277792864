import { VuetifySelectOption } from '@/shared/models/select-options.interface';
import { createProjector } from '@conversa/sink';
import { Program, ProgramsState } from '../models';
export interface ProgramsProjectionsMap {
  'programs.loading': boolean;
  'programs.data': Program[];
  'programs.select-options.all': VuetifySelectOption[];
  'programs.select-options.clinician': VuetifySelectOption[];
  'programs.selected': Program;
}

export const programProjector = createProjector<
  ProgramsProjectionsMap,
  ProgramsState
>();
