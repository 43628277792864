import vuetify from '@/plugins/vuetify';
import VueCompositionApi from '@vue/composition-api';
import Vue from 'vue';
import VueCookies from 'vue-cookies';
import VueTippy from 'vue-tippy/dist/vue-tippy.esm';
import VueTelInput from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css';
import App from './App.vue';
import router from './router';

Vue.config.productionTip = false;
Vue.config.devtools = true;

Vue.use(VueCompositionApi);
Vue.use(VueTippy);
Vue.use(VueCookies);
Vue.use(VueTelInput, {
  mode: 'national',
  defaultCountry: 'US',
  inputOptions: {
    placeholder: 'Phone',
  },
  dropdownOptions: {
    showDialCodeInList: true,
    showFlags: true,
  },
});

new Vue({
  router,
  vuetify,
  render: h => h(App),
}).$mount('#app');
