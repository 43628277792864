































import { defineComponent, ref } from '@vue/composition-api';

export default defineComponent({
  props: {
    buttonLabel: {
      type: String,
      required: false,
      default: 'Search',
    },
    // Note: this is needed until all form inputs are using Vuetify and are using the "rules" prop for validation
    overrideFormValidity: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup() {
    const isFormValid = ref(true);

    return {
      isFormValid,
      scrollToTop: () => window.scrollTo(0, 0),
    };
  },
});
